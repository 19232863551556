function IcSang({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="22.409" height="21.576" viewBox="0 0 22.409 21.576">
      <g id="blood_cells" transform="translate(-5.506 -6)">
        <path
          id="Tracé_84137"
          data-name="Tracé 84137"
          d="M19.494,13.084c0,.276-.225.672-.919,1.036a6.025,6.025,0,0,1-2.722.582,6.024,6.024,0,0,1-2.722-.582c-.694-.364-.919-.76-.919-1.036,0-.241.166-.572.689-.9l-.65-1.025A2.365,2.365,0,0,0,11,13.084a2.531,2.531,0,0,0,1.569,2.111,7.223,7.223,0,0,0,3.285.721,7.224,7.224,0,0,0,3.285-.721,2.531,2.531,0,0,0,1.569-2.111,2.365,2.365,0,0,0-1.253-1.928l-.65,1.025C19.328,12.512,19.494,12.843,19.494,13.084Z"
          transform="translate(-2.161 -2.027)"
          fill={fill}
        />
        <path
          id="Tracé_84138"
          data-name="Tracé 84138"
          d="M5.806,11.764A5.351,5.351,0,0,0,8.72,16.238C6.005,17.853,4.769,20.8,5.959,22.859c1.2,2.082,4.428,2.471,7.2.868,2.69-1.553,3.972-4.406,2.955-6.476,3.17-.747,5.463-2.921,5.463-5.487C21.581,8.581,18.05,6,13.693,6S5.806,8.581,5.806,11.764Zm12.749,3.1a3.913,3.913,0,0,0,1.813-3.1,3.913,3.913,0,0,0-1.813-3.1,8.3,8.3,0,0,0-4.861-1.455A8.3,8.3,0,0,0,8.832,8.668a3.913,3.913,0,0,0-1.813,3.1,3.913,3.913,0,0,0,1.813,3.1,8.3,8.3,0,0,0,4.861,1.455A8.3,8.3,0,0,0,18.555,14.86Zm-6.338,2.567a10.74,10.74,0,0,0,1.333.1,1.723,1.723,0,0,1,.313.4,2.135,2.135,0,0,1-.1,2.1,4.6,4.6,0,0,1-1.747,1.711,4.6,4.6,0,0,1-2.356.657,2.135,2.135,0,0,1-1.874-.964,2.038,2.038,0,0,1-.021-1.855l1.113.484a.845.845,0,0,0-.041.764.951.951,0,0,0,.853.358,3.4,3.4,0,0,0,1.719-.5A3.4,3.4,0,0,0,12.7,19.447a.951.951,0,0,0,.116-.918.845.845,0,0,0-.682-.346Z"
          fill={fill}
        />
        <path
          id="Tracé_84139"
          data-name="Tracé 84139"
          d="M34.438,22.127c1.453.389,2.868-.179,3.16-1.268s-.649-2.289-2.1-2.678-2.868.179-3.16,1.268S32.985,21.737,34.438,22.127Z"
          transform="translate(-10.53 -4.742)"
          fill={fill}
        />
        <path
          id="Tracé_84140"
          data-name="Tracé 84140"
          d="M34.577,32.416c-.65,2.426-3.8,3.69-7.034,2.823s-5.33-3.536-4.68-5.962,3.8-3.69,7.034-2.823S35.227,29.99,34.577,32.416Zm-4.21-.656c.5-.149.725-.4.782-.611a.909.909,0,0,0-.253-.783l.95-.755a2.1,2.1,0,0,1,.475,1.853,2.2,2.2,0,0,1-1.611,1.461,5.107,5.107,0,0,1-4.752-1.273,2.2,2.2,0,0,1-.665-2.07,2.1,2.1,0,0,1,1.338-1.367l.445,1.129a.908.908,0,0,0-.611.552,1.019,1.019,0,0,0,.372.92,3.907,3.907,0,0,0,3.53.946Z"
          transform="translate(-6.779 -7.936)"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default IcSang;
