function IcLivre({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="31.726" height="24.424" viewBox="0 0 31.726 24.424">
      <g id="register_book" transform="translate(-4 -8)">
        <path
          id="Tracé_84151"
          data-name="Tracé 84151"
          d="M23.359,10.427c-1.091.161-2.241.378-3.5.645-1.255-.267-2.405-.484-3.5-.645.987.388,1.991.854,3.05,1.365a.791.791,0,0,1,.447.713.791.791,0,0,1,.447-.713C21.368,11.281,22.372,10.815,23.359,10.427Zm-3.5,19.808a.8.8,0,0,1-.793.8v.156a35.589,35.589,0,0,0-13.484-.017V12.494c.549-.125,1.076-.235,1.586-.331V10.551c-.814.146-1.666.327-2.573.545a.786.786,0,0,0-.6.766V32.118a.8.8,0,0,0,.955.783,35.115,35.115,0,0,1,14.756.012.893.893,0,0,0,.3,0A35.117,35.117,0,0,1,34.771,32.9a.8.8,0,0,0,.955-.783V11.862a.786.786,0,0,0-.6-.766c-.907-.219-1.759-.4-2.573-.545v1.612c.51.1,1.038.206,1.586.331V31.173a35.592,35.592,0,0,0-13.484.017v-.156A.8.8,0,0,1,19.863,30.235Z"
          transform="translate(0 -0.502)"
          fill={fill}
        />
        <path
          id="Tracé_84152"
          data-name="Tracé 84152"
          d="M8.586,9.988V27.873h0a.028.028,0,0,0,.006.007.051.051,0,0,0,.042.01c4.235-.777,7.418.174,11.037,1.847l.007,0h0a.022.022,0,0,0,.009,0l0,0h0V12C15.741,10.1,12.763,8.926,8.586,9.988ZM19.69,12ZM8.169,8.457c4.774-1.222,8.207.184,12.213,2.119A1.584,1.584,0,0,1,21.277,12v17.73a1.6,1.6,0,0,1-2.272,1.443c-3.474-1.606-6.3-2.42-10.085-1.727A1.619,1.619,0,0,1,7,27.873V9.977A1.563,1.563,0,0,1,8.169,8.457Z"
          transform="translate(-0.621)"
          fill={fill}
        />
        <path
          id="Tracé_84153"
          data-name="Tracé 84153"
          d="M35.69,9.988V27.873h0a.027.027,0,0,1-.006.007.051.051,0,0,1-.042.01c-4.235-.777-7.418.174-11.037,1.847l-.007,0h0a.022.022,0,0,1-.009,0l0,0h0V12C28.536,10.1,31.514,8.926,35.69,9.988ZM24.586,12ZM36.108,8.457c-4.774-1.222-8.207.184-12.213,2.119A1.586,1.586,0,0,0,23,12v17.73a1.6,1.6,0,0,0,2.272,1.443c3.474-1.606,6.3-2.42,10.085-1.727a1.619,1.619,0,0,0,1.921-1.577V9.977A1.563,1.563,0,0,0,36.108,8.457Z"
          transform="translate(-3.93)"
          fill={fill}
        />
        <path
          id="Tracé_84154"
          data-name="Tracé 84154"
          d="M11.006,28.588a16.567,16.567,0,0,1,7.623,1.525l.6-1.469A18.114,18.114,0,0,0,10.99,27Z"
          transform="translate(-1.446 -3.93)"
          fill={fill}
        />
        <path
          id="Tracé_84155"
          data-name="Tracé 84155"
          d="M11.006,24.588a16.567,16.567,0,0,1,7.623,1.525l.6-1.469A18.114,18.114,0,0,0,10.99,23Z"
          transform="translate(-1.446 -3.103)"
          fill={fill}
        />
        <path
          id="Tracé_84156"
          data-name="Tracé 84156"
          d="M11.006,20.588a16.567,16.567,0,0,1,7.623,1.525l.6-1.469A18.114,18.114,0,0,0,10.99,19Z"
          transform="translate(-1.446 -2.275)"
          fill={fill}
        />
        <path
          id="Tracé_84157"
          data-name="Tracé 84157"
          d="M13.086,13v2.379H11.5V13Z"
          transform="translate(-1.551 -1.034)"
          fill={fill}
        />
        <path
          id="Tracé_84160"
          data-name="Tracé 84160"
          d="M34.845,28.588a16.567,16.567,0,0,0-7.623,1.525l-.6-1.469A18.114,18.114,0,0,1,34.861,27Z"
          transform="translate(-4.679 -3.93)"
          fill={fill}
        />
        <path
          id="Tracé_84161"
          data-name="Tracé 84161"
          d="M34.845,24.588a16.567,16.567,0,0,0-7.623,1.525l-.6-1.469A18.114,18.114,0,0,1,34.861,23Z"
          transform="translate(-4.679 -3.103)"
          fill={fill}
        />
        <path
          id="Tracé_84162"
          data-name="Tracé 84162"
          d="M34.845,20.588a16.567,16.567,0,0,0-7.623,1.525l-.6-1.469A18.114,18.114,0,0,1,34.861,19Z"
          transform="translate(-4.679 -2.275)"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default IcLivre;
