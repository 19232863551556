import { useState, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PreviousMenuContext from 'contexts/PreviousMenuState';
import { MenuStatesValues } from 'contexts/MenuContext';
import UserContext from 'contexts/UserContext';
import Route from 'components/ui/Route/Route';
import { User } from 'requests/types';
import startup from 'utils/startup';

import CardIdContext from 'contexts/CardIdContext';
import ProtectedRoute from 'components/ui/Route/ProtectedRoute';
import DashboardAdmin from './DashboardAdmin/DashboardAdmin';
import Indication from './Indication/Indication';
import AsthmaSevere from './AsthmaSevere/AsthmaSevere';
import PageFavoris from './PageFavoris';
import Note from './Note/Note';
import PageStatique from './PageStatique';
import AuteurContainer from './AuteurContainer/AuteurContainer';
import HomeContainer from './Home/HomeContainer';
import LoginContainer from './Login/LoginContainer';
import DetailsContainer from './DetailsMedication/DeatilsMedication';
import NotFoundContainer from './NotFoundConatiner/NotFoundConatiner';
import PageVideos from './PageVideoEducatives';
import DetailsNoteContainer from './DetailsNotes/DeatilsNotes';
import Classes from './ClassesTherapeutic/ClassesTherapeutic';
import DCI from './DCI/DCI';

const RootContainer = () => {
  const [user, setUser] = useState(null as User | null);
  const [startupEnded, setStartupEnded] = useState(false);
  const [cardId, setCardId] = useState<string | any>('');
  const [PreviousStates, setPreviousStates] = useState<MenuStatesValues | any>(MenuStatesValues.mainMenu);
  useEffect(() => {
    startup().then((u) => {
      setUser(u);
      setStartupEnded(true);
    });
  }, []);

  if (!startupEnded) return <div />;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <PreviousMenuContext.Provider value={{ PreviousStates, setPreviousStates }}>
        <CardIdContext.Provider value={{ cardId, setCardId }}>
          <Switch>
            <ProtectedRoute footer={false} header={false} path="/admin" protected component={DashboardAdmin} />
            <Route path="/asthmeSevere" exact protected component={AsthmaSevere} />
            <Route path="/indications" exact protected component={Indication} />
            <Route path="/" exact protected component={HomeContainer} />
            <Route path="/classes" exact protected component={Classes} />
            <Route path="/dci" exact protected component={DCI} />
            <Route path="/favoris" exact protected component={PageFavoris} />
            <Route path="/notes" exact protected component={Note} />
            <Route path="/informations" exact protected component={PageStatique} />
            <Route path="/videos" exact protected component={PageVideos} />
            {/* Page Questionnaire
        Page Questionnaire Partie Final */}
            <Route path="/auteurs" exact protected component={AuteurContainer} />
            <Route path="/medicament/:id" exact protected component={DetailsContainer} />
            <Route path="/notes/:id" exact protected component={DetailsNoteContainer} />
            <Route footer={false} header={false} path="/login" exact component={LoginContainer} />
            <Route path="/404" component={NotFoundContainer} />
            <Redirect from="*" to="/404" />
          </Switch>
        </CardIdContext.Provider>
      </PreviousMenuContext.Provider>
    </UserContext.Provider>
  );
};

export default RootContainer;
