/* eslint-disable max-len */
import auteur1 from 'assets/png/aut1.jpeg';
import auteur2 from 'assets/png/aut2.jpeg';
import classNames from 'utils/classNames';

interface Props {
  display: string;
}
const Interet = ({ display }: Props) => {
  const DisplayCondition = 'Interet';
  const array = [
    {
      img: auteur1,
      title: 'Valéry Trosini-Désert',
      desc: ' Au cours des 5 dernières années, le Dr Trosini-Désert a perçu des financements pour participer à des congrès de la part des laboratoires AstraZeneca, Boehringer Ingelheim, Chiesi, Glaxo Smithkline, Novartis et Teva. ',
    },
    {
      img: auteur2,
      title: 'Camille TAILLÉ',
      desc: (
        <div>
          Camille Taillé a reçu des honoraires pour des activités de conseil, de formation ou de communication de Astra
          Zeneca, GSK, Sanofi, Novartis, Chiesi, Teva.
        </div>
      ),
    },
  ];

  return (
    <div className={classNames(display === DisplayCondition ? 'block' : 'hidden')}>
      <div className="flex flex-col lg:grid lg:grid-cols-2 sm:flex sm:flex-col">
        {array.map((m) => (
          <div className="flex flex-row lg:flex-col lg:items-center justify-center items-start  mb-5 lg:pr-4 lg:mb-8 sm:mb-5">
            <img src={m.img} alt="" className="rounded-5  lg:w-full lg:h-96" width="168px" height="300px" />{' '}
            {/* </div> */}
            <div className="flex flex-col flex-1 ml-4	lg:pt-4 lg:ml-0">
              <div className="2xl:text-base text-blue-600 text-26 font-semibold"> {m.title} </div>
              <div className="2xl:text-xs sm:text-sm text-lg text-blue-600 font-regular mt-5 text-justify	lg:text-xs">
                {' '}
                {m.desc}{' '}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interet;
