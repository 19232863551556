function IcEternue({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="31.4" height="29.656" viewBox="0 0 31.4 29.656">
      <g id="coughing_alt" transform="translate(-6 -6)">
        <path
          id="Tracé_84141"
          data-name="Tracé 84141"
          d="M23.412,19.973H20.586v-.826h.924a2.425,2.425,0,0,0,1.662-.65,2.18,2.18,0,0,0,.7-1.573V15.463c0,.061.381.025.564.008h0l.092-.008a.678.678,0,0,0,.415-.228.613.613,0,0,0,.148-.433,2.035,2.035,0,0,0-.241-.673l-.321-.775c-.228-.533-.455-1.067-.67-1.613a17.177,17.177,0,0,0-.255-1.7,5.717,5.717,0,0,0-2.155-2.907,6.189,6.189,0,0,0-7.009-.089A5.677,5.677,0,0,0,12.262,9.8a5.363,5.363,0,0,0-.081,3.441,5.64,5.64,0,0,0,2.043,2.845v3.887H11.759a5.915,5.915,0,0,0-4.021,1.542A5.322,5.322,0,0,0,6,25.282V35.656H29.547V26.108a5.671,5.671,0,0,0-.406-2.3,5.91,5.91,0,0,0-1.311-1.978,6.277,6.277,0,0,0-2.013-1.346A6.558,6.558,0,0,0,23.412,19.973Zm-4.454,8.7c-.06,0-.139-.008-.232-.017a4.217,4.217,0,0,1-.759-.142,2.745,2.745,0,0,1-.628-.266,2.745,2.745,0,0,1-.628.266,4.217,4.217,0,0,1-.759.142c-.1.01-.184.015-.246.017l-.075,0h0c.057,1.631.082,3.746-.856,4.681-1.23,1.226-3.689,1.84-4.919.818s.82-11.037,3.689-11.037c1.991,0,2.064,2.391,2.054,3.795h.037c.034,0,.086,0,.149-.011a2.485,2.485,0,0,0,.44-.081.939.939,0,0,0,.244-.1V22.59h1.744v4.149a.939.939,0,0,0,.244.1,2.485,2.485,0,0,0,.44.081l.1.008c-.01-1.4.064-3.791,2.054-3.791,2.869,0,4.919,10.015,3.689,11.037s-3.689.409-4.919-.818C18.876,32.423,18.9,30.306,18.959,28.675Zm-3.365-1.531v.548c0-.091,0-.178,0-.261s0-.183,0-.287Zm6.979-10.677a.872.872,0,1,1-.872-.872A.872.872,0,0,1,22.572,16.467Zm-3.489,0a.872.872,0,1,0-.872-.872A.872.872,0,0,0,19.083,16.467Z"
          transform="translate(0)"
          fill={fill}
        />
        <path
          id="Tracé_84142"
          data-name="Tracé 84142"
          d="M31.744,13.872A.872.872,0,1,1,30.872,13,.872.872,0,0,1,31.744,13.872Z"
          transform="translate(-3.067 -0.894)"
          fill={fill}
        />
        <path
          id="Tracé_84143"
          data-name="Tracé 84143"
          d="M33.744,16.872A.872.872,0,1,1,32.872,16,.872.872,0,0,1,33.744,16.872Z"
          transform="translate(-3.322 -1.278)"
          fill={fill}
        />
        <path
          id="Tracé_84144"
          data-name="Tracé 84144"
          d="M35.744,19.872A.872.872,0,1,1,34.872,19,.872.872,0,0,1,35.744,19.872Z"
          transform="translate(-3.578 -1.661)"
          fill={fill}
        />
        <path
          id="Tracé_84145"
          data-name="Tracé 84145"
          d="M41.744,18.872A.872.872,0,1,1,40.872,18,.872.872,0,0,1,41.744,18.872Z"
          transform="translate(-4.344 -1.533)"
          fill={fill}
        />
        <path
          id="Tracé_84146"
          data-name="Tracé 84146"
          d="M41.744,13.872A.872.872,0,1,1,40.872,13,.872.872,0,0,1,41.744,13.872Z"
          transform="translate(-4.344 -0.894)"
          fill={fill}
        />
        <path
          id="Tracé_84147"
          data-name="Tracé 84147"
          d="M41.744,9.872A.872.872,0,1,1,40.872,9,.872.872,0,0,1,41.744,9.872Z"
          transform="translate(-4.344 -0.383)"
          fill={fill}
        />
        <path
          id="Tracé_84148"
          data-name="Tracé 84148"
          d="M37.744,11.872A.872.872,0,1,1,36.872,11,.872.872,0,0,1,37.744,11.872Z"
          transform="translate(-3.833 -0.639)"
          fill={fill}
        />
        <path
          id="Tracé_84149"
          data-name="Tracé 84149"
          d="M37.744,15.872A.872.872,0,1,1,36.872,15,.872.872,0,0,1,37.744,15.872Z"
          transform="translate(-3.833 -1.15)"
          fill={fill}
        />
        <path
          id="Tracé_84150"
          data-name="Tracé 84150"
          d="M29.744,18.872A.872.872,0,1,1,28.872,18,.872.872,0,0,1,29.744,18.872Z"
          transform="translate(-2.811 -1.533)"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default IcEternue;
