import { Link, matchPath, useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import MedecineCount from '../MedecineCount/MedecineCount';

interface Props {
  data: any;
  title: string;
  type?: string;
  pathname?: string;
  array?: boolean;
  count?: boolean;
  className?: string;
  search?: boolean;
  sortFunction?: (a: any, b: any) => any;
}

const MedecineMenu: React.FC<Props> = ({
  data,
  title,
  type,
  pathname = '',
  array,
  count,
  className,
  search,
  sortFunction = (a: any, b: any) => a?.dci?.name.localeCompare(b?.dci?.name),
}) => {
  const location = useLocation();
  return (
    <div className="pl-12 py-14 flex flex-col bg-white" style={{ height: 'fit-content' }}>
      <div className="text-blue-600 font-semibold text-base pb-1.5 self-start">
        {title}
        <div className="border-t-2 border-grey w-full" />
      </div>
      <div
        className={classNames(
          'capitalize grid grid-flow-col grid-rows-5 gap-x-9 font-medium font-sans text-sm ',
          className,
        )}
      >
        {data?.sort(sortFunction).map((e: any) =>
          type ? (
            <MedecineCount
              search={search}
              count={count}
              array={array}
              key={e.id}
              pathname={pathname}
              type={type}
              name={e.name}
              id={e.id}
              relatedName={e?.lab?.name}
            />
          ) : (
            <Link key={e.id} to={`/medicament/${e.id}?from=home`} className="flex py-1.5">
              <div
                className={classNames(
                  ` truncate hover:text-green ${matchPath(location.pathname, `/medicament/${e.id}`) && 'text-green'}`,
                )}
              >
                {e.name?.split('®').map((name: any, index: number, tab: any) => (
                  <>
                    {name}
                    {tab.length > 1 && index !== tab.length - 1 && <span className="text-2xl">®</span>}
                  </>
                ))}
              </div>
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default MedecineMenu;
