/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import pdfIcon from 'assets/svg/pdf.svg';
import pdfWhite from 'assets/svg/pdfWhite.svg';
import Button from 'components/Button';
import Card from 'components/common/Card/Card';
import Search from 'components/common/Search/Search';
import ZephirDescription from 'components/common/ZephirDescription';
import UserContext from 'contexts/UserContext';
import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyAnswers } from 'requests/answer';
import { useQuestions } from 'requests/questions';
import classNames from 'utils/classNames';
import { Spinner } from 'utils/loading';
import { decodeUri } from 'utils/url';
import RightArrow from '../../assets/svg/arrowRightWhite.svg';
import DownRow from '../../assets/svg/downRow.svg';
import CardQuestion from '../../components/common/CardQuestion/CardQuestion';
import classes2 from '../HomeGeneric/HomeGeneric.module.scss';
import classes from './Indication.module.scss';

export const PER_PAGE = 100;
interface Props {
  title: React.ReactNode;
  filterComponent: React.ReactNode;
  typeFilter?: string;
}
const Indication: React.FC<Props> = ({ title }) => {
  const location = useLocation();
  const uri = decodeUri(location.search);
  const [responsive, setresponsive] = useState(window.innerWidth < 1000);
  const { user } = useContext(UserContext);
  const { setMenuStates } = useContext(MenuContext);
  const [counter, setCounter] = useState<number>(1);
  const [progress, setProgress] = useState<number>(20);
  const [openTable, setOpenTable] = useState<boolean>(false);
  const [openQuestion, setOpenQuestion] = useState<boolean>(false);
  // const [videoModal, setvideoModal] = useState(false);

  const [cursorChange, setCursorChange] = useState<{ pdf1: boolean; pdf2: boolean; pdf3: boolean }>({
    pdf1: false,
    pdf2: false,
    pdf3: false,
  });
  const [form, setForm] = useState<{ Q1: string; Q2: string; Q3: string; Q4: string; Q5: string }>({
    Q1: '',
    Q2: '',
    Q3: '',
    Q4: '',
    Q5: '',
  });
  const [answerReponse, setAnswerReponse] = useState<{ id: string; answer: string; reponse: string }[]>([]);
  const [dataCall, { data: dataAnswers, loading }] = useLazyAnswers({
    fetchPolicy: 'no-cache',
  });
  const { data: dataQuestions } = useQuestions({
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (counter === 6) {
      dataCall({
        variables: {
          Q1: form.Q1,
          Q2: form.Q2,
          Q3: form.Q3,
          Q4: form.Q4,
          Q5: form.Q5,
        },
      });
      const el = document.getElementById('quesstion');
      el?.scrollIntoView();
    }
  }, [counter]);
  useEffect(() => {
    if (uri.medId) {
      const el = document.getElementById(uri.medId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, uri]);
  const checkResponsive = () => {
    if (window.innerWidth < 1000) {
      setresponsive(true);
    } else {
      setMenuStates(MenuStatesValues.CLOSE);
      setresponsive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', checkResponsive);
    return () => {
      window.removeEventListener('resize', checkResponsive);
    };
  }, []);

  const yesFunction = (Answer: string) => {
    const keyForm: string = `Q${counter}`;

    setForm({ ...form, [keyForm]: 'oui' });
    setAnswerReponse([
      ...answerReponse.filter((e) => e?.id !== keyForm),
      { id: keyForm, answer: Answer, reponse: 'Oui' },
    ]);

    setCounter(counter + 1);
    setProgress(progress + 20);
  };

  const backFunction = () => {
    const keyForm: string = `Q${counter - 1}`;
    setAnswerReponse([...answerReponse.filter((e) => e?.id !== keyForm)]);
    setForm({ ...form, [keyForm]: '' });
    setCounter(counter - 1);
    setProgress(progress - 20);
  };
  const noFunction = (Answer: string) => {
    const keyForm: string = `Q${counter}`;
    setForm({ ...form, [keyForm]: 'non' });
    setAnswerReponse([
      ...answerReponse.filter((e) => e?.id !== keyForm),
      { id: keyForm, answer: Answer, reponse: 'Non' },
    ]);
    setCounter(counter + 1);
    setProgress(progress + 20);
  };
  const idkFunction = (Answer: string) => {
    const keyForm: string = `Q${counter}`;
    setForm({ ...form, [keyForm]: 'Ne sait pas' });
    setAnswerReponse([
      ...answerReponse.filter((e) => e?.id !== keyForm),
      { id: keyForm, answer: Answer, reponse: 'Ne sait pas' },
    ]);
    setCounter(counter + 1);
    setProgress(progress + 20);
  };
  const initialFunction = () => {
    setAnswerReponse([]);
    setCounter(1);
    setProgress(20);
    setOpenTable(false);
    setOpenQuestion(true);
    const el = document.getElementById('quesstion');
    el?.scrollIntoView();
  };

  return (
    <>
      <Search />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div
        className={classNames('bg-grey-200  sm:mb-4  mb-auto', !responsive ? classes2.container2 : classes.container)}
      >
        <div>
          {user?.email !== 'testuser23@yopmail.com' && (
            <div className=" font-medium text-base text-blue-600 bg-grey-300 sm:text-lg w-full  px-36 xl:px-4 text-center py-28 2xl:py-4 sm:px-5 ">
              {' '}
              <span className="font-bold sm:text-sm ">
                Aide au choix thérapeutique dans l’asthme sévère non contrôlé malgré un traitement par CSI fortes doses
                + LABA bien conduit
              </span>
              <br /> <br />
              <div className="font-bold text-sm text-red-550 flex flex-row gap-1 sm:gap-4 justify-center items-center lg:flex-col 2xl:flex-col mb-4">
                <div>
                  La décision au choix thérapeutique est complexe et doit être faite de manière collégiale en RCA
                  (réunion de concertation asthme).{' '}
                </div>
                <div
                  onClick={() => {
                    window.open(
                      'https://splf.fr/wp-content/uploads/2021/02/QUESTIONNAIRE-ASTHME-G2A-2021-web.pdf'.trim(),
                      '_blank',
                    );
                  }}
                  onMouseEnter={() => setCursorChange({ ...cursorChange, pdf1: true })}
                  onMouseLeave={() => setCursorChange({ ...cursorChange, pdf1: false })}
                  className="flex cursor-pointer  flex-row lg:m-auto items-center sm:pb-4 sm:pt-2 border-2 border-green hover:bg-green text-black hover:text-white  p-1 rounded-lg	"
                >
                  <span>
                    {cursorChange?.pdf1 ? (
                      <img src={pdfWhite} alt="" className="h-6 sm:w-6 m-auto" />
                    ) : (
                      <img src={pdfIcon} alt="" className="h-6 sm:w-6 m-auto" />
                    )}
                  </span>
                  {/* eslint-disable-next-line  */}
                  <p className="md:text-sm pl-2 pr-2 font-semibold hover:text-white underline ">
                    TELECHARGER LA FICHE RCA
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {user?.email === 'testuser23@yopmail.com' && (
          <div>
            {counter < 6 ? (
              <>
                <div className={classNames('text-grey-400 text-2xl font-bold mt-auto', classes.title)}>{title}</div>
                {loading ? (
                  <div className="absolute top-1/3 right-1/2">
                    <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
                  </div>
                ) : (
                  <>
                    <div className=" font-medium text-base text-blue-600 bg-grey-300 sm:text-lg w-full  px-36 xl:px-4 text-center py-28 2xl:py-4 sm:px-5 ">
                      {' '}
                      <span className="font-bold sm:text-sm ">
                        Aide au choix thérapeutique dans l’asthme sévère non contrôlé malgré un traitement par CSI
                        fortes doses + LABA bien conduit
                      </span>
                      <br /> <br />
                      <div className="font-bold text-sm text-red-550 flex flex-row gap-1 sm:gap-4 justify-center items-center lg:flex-col 2xl:flex-col mb-4">
                        <div>
                          La décision au choix thérapeutique est complexe et doit être faite de manière collégiale en
                          RCA (réunion de concertation asthme).{' '}
                        </div>
                        <div
                          onClick={() => {
                            window.open(
                              'https://splf.fr/wp-content/uploads/2021/02/QUESTIONNAIRE-ASTHME-G2A-2021-web.pdf'.trim(),
                              '_blank',
                            );
                          }}
                          onMouseEnter={() => setCursorChange({ ...cursorChange, pdf1: true })}
                          onMouseLeave={() => setCursorChange({ ...cursorChange, pdf1: false })}
                          className="flex cursor-pointer  flex-row lg:m-auto items-center sm:pb-4 sm:pt-2 border-2 border-green hover:bg-green text-black hover:text-white  p-1 rounded-lg	"
                        >
                          <span>
                            {cursorChange?.pdf1 ? (
                              <img src={pdfWhite} alt="" className="h-6 sm:w-6 m-auto" />
                            ) : (
                              <img src={pdfIcon} alt="" className="h-6 sm:w-6 m-auto" />
                            )}
                          </span>
                          {/* eslint-disable-next-line  */}
                          <p className="md:text-sm pl-2 pr-2 font-semibold hover:text-white underline ">
                            TELECHARGER LA FICHE RCA
                          </p>
                        </div>
                      </div>
                      <div className="leading-6 text-sm w-8/12 2xl:w-10/12 m-auto flex flex-col justify-center xl:w-full sm:text-left lg:w-10/12">
                        {' '}
                        <p>
                          Pour vous aider à vous guider dans votre choix de biothérapie pour un patient donné,{' '}
                          <span className="font-bold">et vous aider à préparer la RCA</span>, nous vous proposons
                          quelques questions sur les marqueurs biologiques d’importance qui permettrons d’orienter la
                          décision en RCA (réunion de concertation asthme):
                        </p>
                        <div className="flex flex-col m-auto text-left mt-2">
                          <span> - Statut allergique </span>

                          <span>- Taux d’IgE totales</span>

                          <span>- Taux le plus élevé d’éosinophiles dans les 12 mois précédents</span>
                          <span>- Taux de FENO </span>
                        </div>
                      </div>
                    </div>
                    <div className="pb-4 bg-grey-300">
                      <div className=" border-2 border-yellow-100 font-semibold text-red-550 px-4 w-7/12 text-center  lg:w-full m-auto text-sm py-4">
                        <span className="flex mx-2 mb-2 w-full  m-auto justify-center items-center ">
                          <span className="text-lg underline ">Attention</span>
                        </span>
                        <span className="pr-2">
                          Cette aide au choix (questionnaire) n’est basée que sur les marqueurs biologiques et ne prends
                          pas en compte les éléments cliniques tels que la présence d’une polypose naso-sinusienne et/ou
                          présence d’une dermatite atopique sévère, qui sont des éléments à prendre également en compte
                          dans le choix du médicament. Ces éléments cliniques associés aux marqueurs biologiques seront
                          à considérer en RCA (réunion de concertation asthme)
                        </span>
                        <div
                          onMouseEnter={() => setCursorChange({ ...cursorChange, pdf2: true })}
                          onMouseLeave={() => setCursorChange({ ...cursorChange, pdf2: false })}
                          className="flex flex-row w-64 m-auto my-2 lg:m-auto items-center sm:pb-4 sm:pt-2 border-2 border-green hover:bg-green text-black hover:text-white  p-1 rounded-lg	"
                        >
                          {' '}
                          <span className="">
                            {cursorChange?.pdf2 ? (
                              <img src={pdfWhite} alt="" className="h-6 sm:w-6 m-auto" />
                            ) : (
                              <img src={pdfIcon} alt="" className="h-6 sm:w-6 m-auto" />
                            )}
                          </span>
                          <p
                            className="cursor-pointer md:text-sm pl-2 pr-2 font-semibold hover:text-white underline "
                            onClick={() => {
                              window.open(
                                'https://splf.fr/wp-content/uploads/2021/02/QUESTIONNAIRE-ASTHME-G2A-2021-web.pdf'.trim(),
                                '_blank',
                              );
                            }}
                          >
                            TELECHARGER LA FICHE RCA
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-center py-12">
                      <button
                        className="w-80   h-12 sm:text-sm  text-white  bg-green flex items-center justify-between	rounded-lg"
                        onClick={() => {
                          setOpenQuestion(!openQuestion);
                          const el = document.getElementById('quesstion');
                          el?.scrollIntoView();
                        }}
                      >
                        <span className="ml-4">Démarrer le questionnaire </span>
                        {openQuestion ? (
                          <img
                            width="20"
                            height="50"
                            alt=""
                            src={DownRow}
                            className="float-right mr-4  sm:block h-14 flex items-center"
                          />
                        ) : (
                          <img
                            height="50"
                            width="10"
                            alt=""
                            src={RightArrow}
                            className="float-right mr-4 sm:block  h-14 flex items-center"
                          />
                        )}
                      </button>
                    </div>
                    <div id="quesstion">
                      {openQuestion &&
                        dataQuestions?.questionss?.data?.map((item: any, index: number) => (
                          <CardQuestion
                            setAnswerReponse={setAnswerReponse}
                            setForm={setForm}
                            form={form}
                            answerReponse={answerReponse}
                            counter={counter}
                            setCounter={setCounter}
                            setProgress={setProgress}
                            desc={item.question}
                            yesFunction={yesFunction}
                            noFunction={noFunction}
                            idkFunction={idkFunction}
                            backFunction={backFunction}
                            valueCounter={counter}
                            valueProgress={progress}
                            hiddenValue={index + 1 === counter}
                            possibleAnswersLength={item?.possibleAnswers?.length}
                          />
                        ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="flex flex-col  items-center p-2 px-28 lg:px-0 sm:px-5">
                <div className="font-bold  text-16 text-center w-full xl:px-5 md:px-5 sm:px-0 my-5 ">
                  Résultat du questionnaire :
                </div>
                <div className="font-bold  text-16 text-blue-1050    text-left w-full xl:px-5  md:px-5 sm:px-0  ">
                  I. Rappel du prérequis :
                  <div className=" font-normal text-black text-16 flex  text-left  my-5">
                    Asthme sévère non contrôlé malgré un traitement par CSI fortes doses + LABA bien conduit
                  </div>
                </div>
                <div className="font-bold  text-16 text-blue-1050  flex items-center sm:items-start sm:flex-col text-left w-full xl:px-5  md:px-5 mb-5  sm:px-0 mt-4 ">
                  II. Les caractéristiques biologiques de l’asthme :
                  <button
                    className="w-52 sm:w-48 h-12 sm:text-sm sm:mt-2 text-white  bg-green flex items-center justify-between	rounded  2xl:mr-0 ml-12 sm:ml-0 sm:self-center"
                    onClick={() => setOpenTable(!openTable)}
                  >
                    <span className="ml-4">Voir mes réponses</span>
                    {openTable ? (
                      <img
                        width="20"
                        height="50"
                        alt=""
                        src={DownRow}
                        className="float-right mr-4  sm:block h-14 flex items-center"
                      />
                    ) : (
                      <img
                        height="50"
                        width="10"
                        alt=""
                        src={RightArrow}
                        className="float-right mr-4 sm:block  h-14 flex items-center"
                      />
                    )}
                  </button>
                </div>
                {loading ? (
                  <div className="absolute top-1/3 right-1/2">
                    <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
                  </div>
                ) : (
                  <>
                    <div className="w-full xl:px-5 px-24 md:px-5 sm:px-0">
                      {openTable &&
                        answerReponse?.map((item) => (
                          <div className=" justify-center flex flex-row sm:flex-col h-16 sm:w-80 sm:h-48 w-11/12 mb-4 m-auto">
                            <div className="flex flex-row  h-16 sm:h-32  sm:w-80   w-full bg-green  items-center">
                              <div className="text-white   font-bold border-r-2  border-white bg-green text-lg min-w-18 px-4 mr-6">
                                {item.id}
                              </div>
                              <div className="text-sm text-white border border-green bg-green sm:w-64  min-w-650 mr-2  ">
                                {item.answer.replace(/^./, item.answer[0].toUpperCase())}
                              </div>
                            </div>
                            <div className="flex items-center justify-center text-blue-600 text-base sm:w-80 sm:h-16	 bg-grey-450 w-48 font-bold ">
                              {item.reponse}
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="font-bold  text-16 text-blue-1050 flex  text-left w-full xl:px-5  md:px-5 my-5 sm:px-0  ">
                      III. Les possibilités thérapeutiques à discuter lors de la RCA (réunion de concertation asthme)
                      sont :
                    </div>
                    {dataAnswers && dataAnswers?.answerss?.data[0]?.medsId?.length === 0 ? (
                      <div className=" text-18  lg:my-0 text-left w-full  sm:px-0  lg:px-5">
                        {dataAnswers && dataAnswers?.answerss?.data[0].reponse}
                      </div>
                    ) : null}
                    {dataAnswers && !!dataAnswers?.answerss?.data[0]?.medsId?.length && (
                      <p className="sm:text-sm">*Classés par ordre alphabétiques de DCI</p>
                    )}
                    <div
                      className={`grid   sm:grid-cols-1 gap-4 justify-between gap-y-5 sm:pb-6 
                  ${
                    dataAnswers && dataAnswers?.answerss?.data[0]?.medsId?.length === 1
                      ? 'lg:grid-cols-1 2xl:grid-cols-1 3xl:grid-cols-1 4xl:grid-cols-1 xl:w-6/12 2xl:w-1/5 3xl:w-52 4xl:w-52	sm:w-11/12'
                      : [
                          dataAnswers && dataAnswers?.answerss?.data[0]?.medsId?.length === 2
                            ? '2xl:grid-cols-2 3xl:grid-cols-2 4xl:grid-cols-2 2xl:w-4/12 3xl:w-3/12 4xl:w-3/12 sm:w-11/12 md:w-11/12 lg:w-11/12'
                            : dataAnswers && dataAnswers?.answerss?.data[0]?.medsId?.length === 3
                            ? '2xl:grid-cols-3 3xl:grid-cols-3 4xl:grid-cols-3 lg:grid-cols-2  w-1/2 sm:w-11/12 '
                            : '2xl:grid-cols-4 3xl:grid-cols-4 4xl:grid-cols-4 lg:grid-cols-2  2xl:w-11/12  3xl:w-9/12 4xl:w-9/12',
                        ]
                  } 
                    relative min-h-max   mt-6`}
                    >
                      {dataAnswers?.answerss?.data[0]?.medsId
                        .sort((a, b) => a?.dci?.name.localeCompare(b?.dci?.name, 'en', { sensitivity: 'base' }))
                        .map((e) => {
                          return (
                            <>
                              <Card
                                details={false}
                                classNameButton="text-sm"
                                className=" h-275"
                                from="question"
                                key={e.id}
                                dci={e?.dci?.name}
                                deviceType={e.name}
                                title={e.name}
                                footer={e.lab}
                                medicinePhoto={e.image}
                                medicineId={e.id}
                                videoData={e.video}
                                typeVideoOpen={() => {}}
                                medidineDescription={{
                                  classe: e.classe?.name,
                                  dci: e.dci?.name,
                                  device: 'e.device?.name',
                                  dosage: 'e.dose?.name',
                                }}
                              />
                            </>
                          );
                        })}
                    </div>
                    {dataAnswers?.answerss?.data[0]?.medsId?.length ? (
                      <div className="font-semibold  text-red-550 px-4 w-7/12 text-center m-auto lg:w-full  text-sm py-4">
                        Il convient avant tout choix du médicament (pour les indications et la posologie entre autre) de
                        se référer également à la fiche produit du médicament
                      </div>
                    ) : null}
                    {!!dataAnswers?.answerss?.data[0]?.remarque?.length && (
                      <div className="font-bold  text-16 text-blue-1050  flex flex-col  text-left w-full xl:px-5  md:px-5 mt-8 sm:mt-0 mb-8 sm:px-0 ">
                        <div className="flex flex-row gap-10 sm:gap-5 lg:flex-col lg:gap-5">
                          <p> IV.Le choix du médicament et fiche RCA : </p>
                        </div>

                        {(form?.Q5 === 'non' || form?.Q5 === 'Ne sait pas') &&
                        ((form?.Q1 === 'oui' && form?.Q2 === 'non') ||
                          (form?.Q1 === 'non' && form?.Q2 === 'oui') ||
                          (form?.Q1 === 'non' && form?.Q2 === 'non')) &&
                        form?.Q3 === 'non' &&
                        form?.Q4 === 'non' ? (
                          <p className="font-normal  text-16 text-black flex flex-col  text-left  my-5">
                            {dataAnswers?.answerss?.data[0]?.remarque}
                            <div className="flex items-center sm:flex-col text-black  py-2 ">
                              <div
                                onMouseEnter={() => setCursorChange({ ...cursorChange, pdf3: true })}
                                onMouseLeave={() => setCursorChange({ ...cursorChange, pdf3: false })}
                                className="flex flex-row w-72 sm:w-64 sm:pt-2 my-2 lg:m-auto items-center sm:pb-4 border-2 border-green hover:bg-green text-black hover:text-white  p-1 rounded-lg	"
                              >
                                {' '}
                                <span className="">
                                  {cursorChange?.pdf3 ? (
                                    <img src={pdfWhite} alt="" className="h-6 sm:w-6 m-auto" />
                                  ) : (
                                    <img src={pdfIcon} alt="" className="h-6 sm:w-6 m-auto" />
                                  )}
                                </span>
                                <p
                                  className="cursor-pointer md:text-sm pl-2 pr-2 font-semibold hover:text-white underline "
                                  onClick={() => {
                                    window.open(
                                      'https://splf.fr/wp-content/uploads/2021/02/QUESTIONNAIRE-ASTHME-G2A-2021-web.pdf'.trim(),
                                      '_blank',
                                    );
                                  }}
                                >
                                  TELECHARGER LA FICHE RCA
                                </p>
                              </div>
                            </div>
                          </p>
                        ) : (
                          <p className="font-normal  text-16 text-black   text-left  my-5">
                            <div className="flex flex-row items-center gap-1 2xl:flex-col 2xl:gap-0 2xl:items-start">
                              <span>
                                Le choix du médicament est fait par le médecin, au sein d’une réunion de discussion
                                pluridisciplinaire (RCA){' '}
                              </span>
                              <p className="flex items-center sm:flex-col sm:items-start  text-black  py-2">
                                <div
                                  onMouseEnter={() => setCursorChange({ ...cursorChange, pdf3: true })}
                                  onMouseLeave={() => setCursorChange({ ...cursorChange, pdf3: false })}
                                  className="flex flex-row w-72 sm:w-64 sm:justify-center sm:items-center sm:pt-2 m-auto my-2 lg:m-auto items-center sm:pb-4 border-2 border-green hover:bg-green text-black hover:text-white  p-1 rounded-lg	"
                                >
                                  {' '}
                                  <span className="">
                                    {cursorChange?.pdf3 ? (
                                      <img src={pdfWhite} alt="" className="h-6 sm:w-6 m-auto" />
                                    ) : (
                                      <img src={pdfIcon} alt="" className="h-6 sm:w-6 m-auto" />
                                    )}
                                  </span>
                                  <p
                                    className="cursor-pointer md:text-sm pl-2 pr-2 font-semibold hover:text-white underline "
                                    onClick={() => {
                                      window.open(
                                        'https://splf.fr/wp-content/uploads/2021/02/QUESTIONNAIRE-ASTHME-G2A-2021-web.pdf'.trim(),
                                        '_blank',
                                      );
                                    }}
                                  >
                                    TELECHARGER LA FICHE RCA
                                  </p>
                                </div>
                              </p>{' '}
                            </div>
                            <span>
                              Ce choix doit prendre en compte les caractéristiques biologiques de l’asthme mais aussi
                              les caractéristiques cliniques du patient et la préférence de celui-ci (nombre et
                              fréquence des injections...)
                            </span>
                          </p>
                        )}
                      </div>
                    )}

                    <div className="flex-row w-full flex items-center justify-center  h-20 sm:mt-8">
                      <Button className="w-361 mb-4" variant="octoExtraordinary" onClick={initialFunction}>
                        <div className="flex items-center justify-center gap-5 w-full">
                          <div className="w-40 xl:text-sm text-19"> Retour au début</div>
                        </div>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default Indication;
