import ZephirDescription from 'components/common/ZephirDescription';
import Filter from 'components/icons/Filter';
import React from 'react';
import classNames from 'utils/classNames';
import classes from './HomeGeneric.module.scss';

interface Props {
  responsive: boolean;
  titleFilter: string;
  typeFilter: string | undefined;
  filterComponent: React.ReactNode;
}

const ResponsiveFilter = ({ responsive, titleFilter, typeFilter, filterComponent }: Props) => {
  return (
    <div className={classNames(classes.filter, responsive && 'w-full')}>
      <div className="flex rounded-5 childd bg-transparent ">
        <div className="flex-auto  px-10 py-7 pt-20">
          <div className="flex mb-7  items-center ">
            <Filter height={17} className="pr-6 2xl:pr-5" />

            <div className="font-bold text-2xl 2xl:text-xl">Filtrer par</div>
          </div>

          {(titleFilter === 'Laboratoires' || titleFilter === 'Indications') && (
            <>
              <div className="text-blue-600 font-semibold">{titleFilter} </div>
              <div className="h-1 w-1/2 bg-purple-100 my-2">
                <div className="relative h-full bg-blue w-1/3 left-3.5" />
              </div>
            </>
          )}
          <div className="flex text-sm font-medium flex-col pb-14 h-342 overflow-y-scroll px-4">
            {typeFilter && (
              <div className="block text-base 2xl:text-sm font-medium pb-4 text-grey-500">{typeFilter}</div>
            )}

            {filterComponent}
          </div>
        </div>
      </div>

      <ZephirDescription className="justify-center relative right-2 mt-8" />
    </div>
  );
};

export default ResponsiveFilter;
