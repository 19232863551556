/* eslint-disable no-nested-ternary */
import Modal from 'components/common/Modal/Modal';
import Delete from 'components/icons/Delete';
import FavoriteFilled from 'components/icons/FavoriteFilled';
// import QRCode from 'components/icons/QRCode';
import ModalComponent from 'components/ux/ModalDetailNote';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Favorite as FavoriteItem, useDeleteFavorite } from 'requests/Favoris';
import { useAddFavourite } from 'requests/medicaments';
import { Note as NoteType } from 'requests/types';
import { Spinner } from 'utils/loading';
import { decodeUri } from 'utils/url';
import Pologone from '../../../assets/svg/Polygone.svg';
import Favorite from '../../../components/icons/Favorite';
import Note from '../../../components/icons/Note';
import NoteWhite from '../../../components/icons/NoteIconWhite';

export type HeaderMedicationProps = {
  name: string | undefined;
  onUpdate: () => void;
  onVideoOpen: (open: boolean) => void;
  onVideoSeringueOpen: (open: boolean) => void;
  id: string | undefined;
  QR: { stylo: string; seringue: string } | undefined;
  favorite?: FavoriteItem;
  note?: NoteType;
  refetchNote: () => void;
  video: { seringue: string; stylo: string; qrcode: string; url: string };
};

const MedicationHeader: FunctionComponent<HeaderMedicationProps> = ({
  name,
  id,
  QR,
  onUpdate,
  onVideoOpen,
  onVideoSeringueOpen,
  favorite,
  note,
  refetchNote,
  video,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [addFavouriteFunction, { data, loading }] = useAddFavourite();
  const [deleteFavs, { loading: deleteLoading, data: deleteFavoriteData }] = useDeleteFavorite();
  const location = useLocation();
  const uri = decodeUri(location.search);
  const [openGeneric, setOpenGeneric] = useState(false);
  const [cursorType, setCursorType] = useState(false);
  const [hoverdTitle, setHoveredTitle] = useState(-1);
  useEffect(() => {
    if (data) {
      onUpdate();
    }
  }, [data]);
  useEffect(() => {
    if (deleteFavoriteData) {
      onUpdate();
    }
  }, [deleteFavoriteData]);
  const onOpen = () => {
    setOpen(true);
  };

  const addFavourite = (medicineID?: string) => {
    if (!loading && medicineID) {
      addFavouriteFunction({ variables: { medicine: medicineID } });
    }
  };
  const removeFavourite = () => {
    if (favorite) {
      deleteFavs({ variables: { id: favorite.id } });
    }
  };
  const cursorChangeHandler = (cursor: boolean, index: number) => {
    setCursorType(cursor);
    setHoveredTitle(index);
  };

  const ClasseAA = [
    {
      icon: (
        <div
          className={`${
            !video?.seringue?.length
              ? 'hidden'
              : name !== 'XOLAIR®'
              ? 'cursor-pointer  lg:mr-0 rounded-lg h-6 sm:w-9 lg:w-10 lg:h-10 sm:-ml-6  2xl:-ml-0 xl:-ml-0 lg:-ml-2'
              : 'cursor-pointer  lg:mr-0 rounded-lg h-6 sm:w-9 lg:w-10 lg:h-10 w-10 ml-24 sm:ml-72 lg:ml-7'
          }`}
        >
          <button
            className="w-56 2xl:w-36 lg:w-32 h-10 rounded-lg  text-white  bg-green flex  items-center "
            onClick={() => onVideoSeringueOpen(true)}
          >
            <img height="50" width="20" alt="" src={Pologone} className="mt-1 ml-4  h-14 flex items-center" />
            <div className="text-sm 2xl:text-xs  flex 2xl:flex-col ml-2 text-left">
              <span className="pr-1">Seringue</span> <span>pré-remplie</span>
            </div>
          </button>
        </div>
      ),
    },
    {
      icon: (
        <div
          className={`${
            !video?.stylo?.length
              ? 'hidden'
              : 'cursor-pointer relative lg:mr-0 rounded-lg h-6 sm:w-9 lg:w-10 lg:h-10  xl:ml-0 sm:-ml-8'
          }`}
        >
          <button
            className="w-56 2xl:w-36 lg:w-32 h-10 rounded-lg  text-white  bg-green flex  items-center "
            onClick={() => onVideoOpen(true)}
          >
            <img height="50" width="20" alt="" src={Pologone} className="mt-1  h-14 flex items-center ml-4" />
            <div className="text-sm 2xl:text-xs  flex 2xl:flex-col ml-2 text-left">
              <span className="mr-1">Stylo</span> <span>pré-rempli</span>
            </div>
          </button>
        </div>
      ),
    },
  ];
  const ClasseA = [
    {
      icon: (
        <div onClick={onOpen} className="cursor-pointer relative lg:mr-0 rounded-lg h-6 sm:w-9 lg:w-10 lg:h-10 w-10">
          <div className="bg-green rounded-lg	 p-2 flex justify-center items-center ">
            {/* <Note fill="#FFFFFF" width="20px" height="22px" />{' '} */}
            {note ? <NoteWhite width="22px" height="22px" /> : <Note fill="white" width="22px" height="22px" />}
          </div>
        </div>
      ),
      title: <div onClick={onOpen}> Notes </div>,
    },
    {
      icon: (
        <div className="cursor-pointer relative lg:mr-0 rounded-lg h-6 w-10 sm:w-9 lg:w-10 lg:h-10">
          <>
            {favorite ? (
              <>
                {loading ? (
                  <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
                ) : (
                  <div
                    className="bg-green rounded-lg p-2 flex justify-center items-center "
                    onClick={() => removeFavourite()}
                  >
                    <FavoriteFilled width="22px" height="22px" fill="#FFFFFF" />
                  </div>
                )}
              </>
            ) : (
              <>
                {deleteLoading ? (
                  <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
                ) : (
                  <div
                    className="bg-green rounded-lg p-2 flex justify-center items-center "
                    onClick={() => addFavourite(id)}
                  >
                    <Favorite width="22px" height="22px" fill="#FFFFFF" />
                  </div>
                )}
              </>
            )}
          </>
        </div>
      ),
      title: (
        <>
          {deleteLoading ? (
            'suppression...'
          ) : (
            <div onClick={() => (favorite ? removeFavourite() : addFavourite(id))}>
              {favorite ? 'Supprimer de mes favoris' : 'Ajouter à mes favoris'}
            </div>
          )}
        </>
      ),
    },
    // {
    //   icon: (
    //     <div className="cursor-pointer relative lg:mr-0 rounded-lg h-6 w-10 sm:w-9 lg:w-10 lg:h-10">
    //       <div className="bg-green rounded-lg p-2 h-10">
    //         <p>
    //           <QRCode fill="#FFFFFF" width="22px" height="22px" />{' '}
    //         </p>
    //       </div>
    //     </div>
    //   ),
    //   title: <p>QR Code</p>,
    //   title2: (
    //     <div className="  border-green border-2  rounded-lg text-green   mt-12 flex  items-center     ">
    //       <a
    //         href={`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/unique/${QR?.stylo}`}
    //         target="blank"
    //         download
    //         className="border-green text-center hover:bg-green hover:text-white cursor-pointer p-2 z-50"
    //         style={{ flex: '0.5' }}
    //       >
    //         Stylo
    //       </a>
    //       <a
    //         href={`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/unique/${QR?.seringue}`}
    //         target="blank"
    //         download
    //         className="border-green border-l-2 text-center hover:bg-green hover:text-white cursor-pointer p-2"
    //         style={{ flex: '0.5' }}
    //       >
    //         Seringue
    //       </a>
    //     </div>
    //   ),
    // },
    // {
    //   icon: (
    //     <div className="cursor-pointer relative lg:mr-0 rounded-lg h-6 w-10 sm:w-9 lg:w-10 lg:h-10">
    //       <div className="bg-green rounded-lg	p-2 flex justify-center items-center">
    //         <p>
    //           <QRCode fill="#FFFFFF" width="22px" height="22px" />
    //         </p>
    //       </div>
    //     </div>
    //   ),
    //   title: <p>Planche de QR Code</p>,
    //   title2: (
    //     <div className="  border-green border-2  rounded-lg text-green   mt-12 flex  items-center     ">
    //       <a
    //         href={`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/planche/${QR?.stylo}`}
    //         target="_blank"
    //         download
    //         className="border-green text-center hover:bg-green hover:text-white cursor-pointer p-2 z-50"
    //         style={{ flex: '0.5' }}
    //         rel="noreferrer"
    //       >
    //         Stylo
    //       </a>
    //       <a
    //         href={`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/planche/${QR?.seringue}`}
    //         target="_blank"
    //         download
    //         className="border-green border-l-2 text-center hover:bg-green hover:text-white cursor-pointer p-2"
    //         style={{ flex: '0.5' }}
    //         rel="noreferrer"
    //       >
    //         Seringue
    //       </a>
    //     </div>
    //   ),
    // },
  ];
  return (
    <div className="flex flex-col w-full" {...rest}>
      <div className="flex ">
        <div className="flex flex-col">
          <div className="font-bold text-3xl 2xl:text-2xl lg:text-xl md:text-2xl  text-blue-600 lg:mt-4">{name}</div>
        </div>
        {uri.from === 'generic' && (
          <div
            className="px-4 flex cursor-pointer hover:bg-blue-600 py-2 bg-yellow text-white  text-xs font-medium	place-self-end rounded-3xl"
            onClick={() => setOpenGeneric(true)}
          >
            <Delete height={18} />
            <span className="px-1"> Générique </span>
          </div>
        )}
        <Modal
          className="rounded-5 z-50 relative text-left py-24 px-20 bg-grey-300 w-1088 2xl:w-934	h-661 2xl:h-539		 "
          onClose={() => {
            setOpenGeneric(false);
          }}
          open={openGeneric}
        >
          <div className="text-blue-600 text-2xl  font-semibold">
            Cette spécialité est similaire à la spécialité FORADIL 12 μg, poudre pour inhalation en gélule (même
            composition qualitative et quantitative en principe actif et même forme pharmaceutique). Le mécanisme du
            dispositif d'inhalation fourni dans le conditionnement est identique à celui de FORADIL 12 μg et provient du
            même fabricant. Seules des modifications esthétiques, notamment de couleur, les différencient.
          </div>
        </Modal>
      </div>
      <div className="flex justify-between mb-8">
        <div
          className={`${
            name === 'XOLAIR®'
              ? '-ml-24 lg:-ml-10 xl:-ml-16 flex flex-row gap-1 sm:gap-0  sm:-ml-24 2xl:-ml-24'
              : 'flex  gap-8 lg:gap-4 2xl:gap-4 lg:gap-24   sm:mr-20 ml-0 lg:-ml-1'
          }`}
        >
          {ClasseAA.map((i) => (
            <div className="flex  justify-center items-center  hover:cursor-pointer 2xl:ml-0 xl:m-4 lg:m-3 md:ml-6 ">
              <div className="w-full">{i.icon}</div>
            </div>
          ))}
        </div>
        <div
          className={`${
            name === 'XOLAIR®'
              ? '-ml-32 lg:-ml-10 xl:-ml-16 flex flex-row gap-1 sm:gap-11  sm:-ml-2'
              : 'flex flex-row gap-1 lg:gap-2  sm:-ml-3 '
          }`}
        >
          {ClasseA.map((i, index) => (
            <div
              className="flex flex-col justify-center items-center ml-4 hover:cursor-pointer   md:m-auto"
              onMouseEnter={() => cursorChangeHandler(true, index)}
              onMouseLeave={() => cursorChangeHandler(false, -1)}
            >
              {cursorType && hoverdTitle === index ? (
                <div className="  sm:hidden md:hidden lg:hidden absolute  top-56 font-bold text-xs px-4 py-2  rounded-2xl	 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  ">
                  <div className="  bg-green  rounded-lg text-white text-center  p-3 mb-4 -mt-1   ">{i.title} </div>
                  {/* {i?.title2 ? i?.title2 : null} */}
                </div>
              ) : null}
              <div>{i.icon}</div>
            </div>
          ))}
        </div>
      </div>

      <ModalComponent
        note={note}
        medicine={id}
        onClose={() => {
          setOpen(false);
          refetchNote();
        }}
        open={open}
      />
    </div>
  );
};

export default MedicationHeader;
