import Up from 'assets/svg/goUp.svg';
import ZephirDescription from 'components/common/ZephirDescription';
import HeaderRouterHeader from 'containers/DetailsMedication/Components/HeaderRouter';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Editorial2022 from './Editorial';
import Editorial2023 from './Editorial2023';
import MenuItem from './MenuItem';

interface PagesData {
  name: string;
}

interface StaticPagesInfo {
  [key: string]: PagesData;
}

const PageStatique: FC = () => {
  const INFOS: StaticPagesInfo = {
    Editorial: { name: 'Éditorial' },
    // Editorial2022: { name: 'Éditorial 2022' },
  };
  const [displaySection, setdisplaySection] = useState('Editorial');
  const [currentSection, setcurrentSection] = useState<string | null>('Editorial');

  const { search } = useLocation();
  const history = useHistory();
  const ToRead = useRef<HTMLDivElement>(null);
  const ToMenu = useRef<HTMLDivElement>(null);
  const name = new URLSearchParams(search).get('section');
  const [readSectionActive, setreadSectionActive] = useState(false);
  useEffect(() => {
    history.push(`/informations?section=${displaySection}`);
    if (ToRead.current) {
      ToRead.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    setcurrentSection(name);
  }, [displaySection, search, currentSection]);

  const scrollToMenu = () => {
    if (ToMenu.current) {
      ToMenu.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  return (
    <div className="container bg-grey-200 min-h-80">
      <HeaderRouterHeader pageTitle="À propos" subTitle="Éditorial" />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className="lg:px-6 sm:px-5 px-28 m-auto  flex lg:flex-col xs:flex " ref={ToMenu}>
        <div className="menu-info py-7  flex-0.4">
          {Object.keys(INFOS).map((key) => (
            <MenuItem
              medicamentName={INFOS[key]?.name}
              currentSection={currentSection}
              target={key}
              setdisplaySection={setdisplaySection}
            />
          ))}
        </div>
        <div ref={ToRead} className="contenu-info p-2 flex-1">
          <div className="py-6">
            <h4 className="text-2xl font-bold">Guide alizé</h4>
            <h2 className="text-3xl font-bold text-green sm:text-xl">{INFOS[displaySection]?.name}</h2>
          </div>

          <div className="grid grid-cols-1 text-justify">
            <Editorial2023 display={displaySection} />
            <Editorial2022 display={displaySection} />
          </div>
        </div>
      </div>
      <button
        className={` outline-none fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
        onClick={() => scrollToMenu()}
      >
        <img alt="up" src={Up} height={30} />
      </button>
      <p className="p-8" />
    </div>
  );
};

export default PageStatique;
