import { useDCIS } from 'requests/dcis';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Devices = () => {
  const { data } = useDCIS({ fetchPolicy: 'no-cache' });

  return (
    <MedecineMenu
      sortFunction={(a, b) => a.name.localeCompare(b.name)}
      pathname="/dci"
      type="dci"
      data={data?.dcis.data}
      title="DCI"
    />
  );
};

export default Devices;
