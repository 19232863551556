import logo from 'assets/png/SPLF.svg';
import classNames from 'utils/classNames';

interface ZephireDescriptionProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  height?: number | string;
  width?: number | string;
}
const ZephirDescription: React.FC<ZephireDescriptionProps> = ({ className, width = 68, height = 22 }) => {
  return (
    <div className={classNames('flex items-center', className)}>
      <div className="flex-1 flex flex-row justify-center items-center w-full bg-white text-base sm:text-xs">
        <img src={logo} alt="" width={width} className={`h-${height}`} />
        Guide Alizé est une marque déposée par la SPLF
      </div>
    </div>
  );
};

export default ZephirDescription;
