import { useClasses } from 'requests/classes';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Devices = () => {
  const { data } = useClasses({ fetchPolicy: 'no-cache', variables: { sort: 'name' } });
  return (
    <MedecineMenu
      pathname="/classes"
      type="classe"
      data={data?.classes.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })}
      title="Classes thérapeutiques"
    />
  );
};

export default Devices;
