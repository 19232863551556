import * as React from 'react';

function Polygone({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
      <g id="Polygone_50" data-name="Polygone 50" transform="translate(23) rotate(90)" fill="none">
        <path
          d="M9.711,3.578a2,2,0,0,1,3.578,0l8.264,16.528A2,2,0,0,1,19.764,23H3.236a2,2,0,0,1-1.789-2.894Z"
          stroke="none"
        />
        <path
          d="M 11.49999046325684 4.472126007080078 L 11.49999523162842 4.472135543823242 C 11.49999332427979 4.472135543823242 11.49999141693115 4.472135543823242 11.49999046325684 4.472135543823242 L 3.236064910888672 20.99999618530273 C 3.236066818237305 20.99999618530273 3.236068725585938 20.99999618530273 3.23607063293457 20.99999618530273 L 19.76392936706543 20.99999618530273 C 19.76393127441406 20.99999618530273 19.7639331817627 20.99999618530273 19.76394081115723 21.0000057220459 L 11.49999046325684 4.472126007080078 M 11.49999904632568 2.472137451171875 C 12.21016502380371 2.472137451171875 12.92033004760742 2.840660095214844 13.28884983062744 3.577705383300781 L 21.55278968811035 20.10557556152344 C 22.21768951416016 21.43537521362305 21.25069999694824 22.99999618530273 19.76392936706543 22.99999618530273 L 3.23607063293457 22.99999618530273 C 1.749300003051758 22.99999618530273 0.7823104858398438 21.43537521362305 1.447210311889648 20.10557556152344 L 9.711139678955078 3.577705383300781 C 10.079665184021 2.840660095214844 10.78983211517334 2.472137451171875 11.49999904632568 2.472137451171875 Z"
          stroke="none"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default Polygone;
