import Up from 'assets/svg/goUp.svg';
import ImagePreview from 'components/common/PreviewImage/ImagePreview';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFavorite } from 'requests/Favoris';
import { useMedicamentsById, useRelatedMedicine } from 'requests/medicaments';
import { useMedicineNote } from 'requests/note';
import { Spinner } from 'utils/loading';
import { decodeUri } from 'utils/url';
import moment from 'moment';
import SplfCard from '../../components/Splf';
import ModalVideo from '../../components/ux/ModalVideo/ModalVideo';
import HeaderRouter from './Components/HeaderRouter';
import MedicationHeader from './Components/MedicationHeader';
import MedicationTable from './Components/MedicationTable';
import MedicationTable2 from './Components/MedicationTable2';
import MedicationTable3 from './Components/MedicationTable3';
import TauxPoids from './Components/TauxPoids';

const DetailsContainer = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);
  const [videoModal, setvideoModal] = useState(false);
  const [videoModalSeringue, setVideoModalSeringue] = useState(false);
  const [readSectionActive, setreadSectionActive] = useState(false);

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 700) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  const { data: relatedMedicineData } = useRelatedMedicine({
    variables: {
      ...uri,
      medicine: params.id,
      sort: 'name',
      order: uri.order === '-1' ? -1 : 1,
    },
    fetchPolicy: 'no-cache',
  });
  const [getFavorite, { data: favorite, error }] = useFavorite({
    fetchPolicy: 'no-cache',
  });

  const { data: noteData, refetch: refetchNote } = useMedicineNote({
    variables: { medicine: params.id },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    window.scroll({ top: 0 });
  }, [location]);

  const { data, called, loading } = useMedicamentsById({
    variables: { id: params?.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getFavorite({ variables: { medicine: params.id } });
    refetchNote();
  }, [params.id]);

  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (loading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
      </div>
    );

  const getPageTitle = () => {
    let title = '';
    if (uri?.from === 'favoris' || uri?.from === 'note') {
      title = 'Mon espace';
    } else if (uri?.from === 'chambre') {
      title = "Chambre d'inhalation";
    } else if (uri.from === 'video') title = 'Vidéos éducatives';
    else title = 'Médicaments';
    return title;
  };
  const getVideoData = () => {
    if (videoModal) {
      return data?.medicine.video?.stylo && data?.medicine.video?.qrCode
        ? { url: `${data?.medicine.video?.stylo}23`, qrCode: data?.medicine.video?.QRstylo }
        : { url: '', qrCode: '' };
    }
    if (videoModalSeringue) {
      return data?.medicine.video?.seringue
        ? { url: `${data?.medicine.video?.seringue}23`, qrCode: data?.medicine.video?.QRseringue }
        : { url: '', qrCode: '' };
    }
    return { url: '', qrCode: '' };
  };
  return (
    <>
      {!data && called ? (
        <div className="flex flex-1 items-center justify-center">
          <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
        </div>
      ) : (
        <div className=" w-full h-full flex-1">
          <>
            <HeaderRouter
              onClick={() => {
                if (uri?.from === 'chambre') history.push('/chambreInhalation');
                else if (uri.from === 'video') history.push(`/videos?videoId=${data?.medicine?.id}`);
                else if (uri?.from !== 'favoris' && uri?.from !== 'note') history.push(`/?medId=${data?.medicine?.id}`);
              }}
              className={uri?.from !== 'favoris' && uri?.from !== 'note' ? 'underline cursor-pointer' : ''}
              data={data?.medicine}
              subTitle={data?.medicine.name}
              pageTitle={getPageTitle()}
              previousPage="/"
              next={relatedMedicineData?.relatedMedicine.next}
              previous={relatedMedicineData?.relatedMedicine.previous}
              arrow={uri.from !== 'favoris' && uri.from !== 'question'}
            />
            <div className="bg-grey-200 flex flex-row w-full px-28 py-12 lg:py-0 lg:px-5 sm:px-5  sm:py-0 lg:flex-col  gap-20 2xl:gap-4 lg:m-auto ">
              {width < 1000 && (
                <MedicationHeader
                  video={{
                    stylo: `${data?.medicine.video.stylo}`,
                    seringue: `${data?.medicine.video.seringue}`,
                    url: '',
                    qrcode: '',
                  }}
                  refetchNote={refetchNote}
                  note={noteData?.medicineNote}
                  onUpdate={() => getFavorite({ variables: { medicine: params.id } })}
                  favorite={error ? undefined : favorite?.favorite}
                  onVideoOpen={setvideoModal}
                  onVideoSeringueOpen={setVideoModalSeringue}
                  name={data?.medicine.name}
                  id={data?.medicine.id}
                  QR={data?.medicine.qr}
                />
              )}
              <div className="w-1/3 lg:w-full  flex-0 justify-center items-center mr-2  ">
                <ImagePreview src={data?.medicine?.image} alt="fdfs" className="rounded-5 w-100" />
                {width > 1000 && (
                  <ImagePreview src={data?.medicine?.moleculeImage} alt="fdfs" className="rounded-5 w-100 m-auto m-1" />
                )}

                {data?.medicine.name === 'FASENRA®' && width > 1000 && (
                  <div className="text-xs">
                    ADCC = antibody-dependent cellular cytotoxicity IL 5 = interleukine 5 NK = natural killer
                  </div>
                )}
                {false && <SplfCard colorType />}
              </div>
              <div className="flex flex-col flex-1">
                {width > 1000 && (
                  <MedicationHeader
                    video={{
                      stylo: `${data?.medicine.video.stylo}`,
                      seringue: `${data?.medicine.video.seringue}`,
                      url: '',
                      qrcode: 'ahmed.jpg',
                    }}
                    note={noteData?.medicineNote}
                    onUpdate={() => getFavorite({ variables: { medicine: params.id } })}
                    onVideoOpen={setvideoModal}
                    onVideoSeringueOpen={setVideoModalSeringue}
                    favorite={error ? undefined : favorite?.favorite}
                    name={data?.medicine.name}
                    id={data?.medicine.id}
                    QR={{
                      stylo: data?.medicine?.video?.QRstylo || '',
                      seringue: data?.medicine?.video?.QRseringue || '',
                    }}
                    refetchNote={refetchNote}
                  />
                )}
                <div className="font-semiold text-green text-lg">Description</div>
                <div className="flex-col flex">
                  <MedicationTable
                    name={data?.medicine.name}
                    classe={data?.medicine.classe}
                    classeFull={data?.medicine.classeFull}
                    dci={data?.medicine.dci}
                    lab={data?.medicine.lab}
                    presentation={data?.medicine.presentation}
                    actionModality={data?.medicine.actionModality}
                    actionModalityV2={data?.medicine.actionModalityV2}
                    Indications={data?.medicine.Indications}
                    opinion={data?.medicine.opinion}
                    rcp={data?.medicine.rcp}
                    video={data?.medicine.video}
                    image={data?.medicine.image}
                    moleculeImage={data?.medicine?.moleculeImage}
                    AdministrationMode={data?.medicine.AdministrationMode}
                    frequencyInjection={data?.medicine.frequencyInjection}
                    doseAdaption={data?.medicine.doseAdaption}
                    dataMoleculeImage={data?.medicine?.moleculeImage}
                  />
                </div>
              </div>
            </div>
            {data?.medicine.id === '61795ca851bbae56d43cc484' && <TauxPoids />}
            <div className="bg-grey-200 flex px-20  sm:py-0 sm:px-5 lg:flex-col lg:px-5">
              <MedicationTable2
                name={data?.medicine.name}
                sideEffects={data?.medicine.sideEffects}
                treatmentDuration={data?.medicine.treatmentDuration}
                tableImage={data?.medicine.tableImage}
                predictifFactors={data?.medicine.predictifFactors}
                EffectivenessEvaluation={data?.medicine.EffectivenessEvaluation}
              />
              {false && (
                <MedicationTable2
                  Indications={data?.medicine.Indications}
                  name={data?.medicine.name}
                  rcp={data?.medicine.rcp}
                  opinion={data?.medicine.opinion}
                  sameClasses={data?.medicine.sameClasses}
                  classesAndIndications={data?.medicine.classesAndIndications}
                  Price={data?.medicine.Price}
                  refund={data?.medicine.refund}
                  otherClassesAndIndications={data?.medicine.otherClassesAndIndications}
                />
              )}
              <MedicationTable3
                PregnancyBreastFeeding={data?.medicine.PregnancyBreastFeeding}
                rcp={data?.medicine.rcp}
                opinion={data?.medicine.opinion}
                predictifFactors={data?.medicine.predictifFactors}
                EffectivenessEvaluation={data?.medicine.EffectivenessEvaluation}
                sameClasses={data?.medicine.sameClasses}
                classesAndIndications={data?.medicine.classesAndIndications}
                Price={data?.medicine.Price}
                refund={data?.medicine.refund}
                otherClassesAndIndications={data?.medicine.otherClassesAndIndications}
                otherClasseIndicationComparable={data?.medicine.otherClasseIndicationComparable}
              />
            </div>

            <p className="bg-grey-200 text-right text-sm mt-1 px-28 lg:px-6">
              <b>Mise à jour : </b>
              {moment(data?.medicine.updatedAt).format('MMMM YYYY')}
            </p>
          </>
          <p className="p-8 bg-grey-200" />
          <ModalVideo
            onClose={() => {
              setVideoModalSeringue(false);
              setvideoModal(false);
            }}
            medidineDescription={{
              classe: data?.medicine?.classe?.name,
              dci: data?.medicine?.dci?.name,
            }}
            title={data?.medicine?.name}
            deviceType={`${data?.medicine?.name} ${
              getVideoData().qrCode === data?.medicine?.video?.QRstylo
                ? 'en Stylo pré-rempli'
                : 'en Seringue pré-remplie'
            }`}
            medicineId={data?.medicine?.id}
            videoData={getVideoData()}
            open={videoModal || videoModalSeringue}
            detail={false}
          />
        </div>
      )}
      <button
        className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <img alt="up" src={Up} height={30} />
      </button>
    </>
  );
};

export default DetailsContainer;
