import * as React from 'react';

function Severe({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36.123" height="27.406" viewBox="0 0 36.123 27.406" {...props}>
      <path
        id="Tracé_84121"
        data-name="Tracé 84121"
        d="M375.9,79.81h0a3.047,3.047,0,0,0-4.8,2.5v5.95l-1.9-1.9V73.839h-2.36v12.52l-1.9,1.9v-5.95a3.047,3.047,0,0,0-4.8-2.5h0a23.664,23.664,0,0,0-9.926,17.8,2.883,2.883,0,0,0,2.859,3.134,2.912,2.912,0,0,0,.511-.045l7.258-1.29a4.956,4.956,0,0,0,4.093-4.885V91.569l.013.013,3.064-3.065,3.065,3.065.013-.013v2.955a4.956,4.956,0,0,0,4.094,4.885l7.258,1.29a2.911,2.911,0,0,0,.511.045,2.883,2.883,0,0,0,2.859-3.134A23.664,23.664,0,0,0,375.9,79.81ZM363.238,94.524a3.241,3.241,0,0,1-2.677,3.2l-7.258,1.29a1.147,1.147,0,0,1-.983-.285,1.163,1.163,0,0,1-.376-.966,21.691,21.691,0,0,1,9.2-16.543h0a1.331,1.331,0,0,1,2.094,1.091Zm20.506,4.2a1.149,1.149,0,0,1-.984.285l-7.258-1.29a3.241,3.241,0,0,1-2.677-3.2V82.306a1.31,1.31,0,0,1,.717-1.181,1.308,1.308,0,0,1,1.377.091h0a21.692,21.692,0,0,1,9.2,16.543A1.162,1.162,0,0,1,383.744,98.724Z"
        transform="translate(-349.97 -73.589)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default Severe;
