interface Props {
  display: string;
}
const Editorial2022 = ({ display }: Props) => {
  const DisplayCondition = 'Editorial2022';

  return (
    <div id="editorial" style={{ display: display === DisplayCondition ? 'block' : 'none' }} className="font-regular">
      <p className="text-sm pt-3">
        L’arrivée des anticorps monoclonaux a bouleversé la prise en charge de l’asthme sévère depuis quelques années.
        La multiplication des molécules disponibles, actuellement 5 et probablement 6 prochainement, a complexifié au
        fur et à mesure la stratégie thérapeutique. Si les libellés de la Commission de Transparence posent les
        premières bases pour guider le choix du médecin, celui-ci se heurte vite au fait que les indications se
        recoupent souvent, puisque c’est bien la même population de patients dont l’inflammation bronchique est dominée
        par les éosinophiles et les cytokines de type 2 qui est ciblée par les molécules actuellement disponibles. Aucun
        essai randomisé n’a comparé directement deux molécules, ce qui rend toute recommandation privilégiant une
        molécule par rapport à une autre, impossible pour le moment. Alors comment faire ?
      </p>
      <p className="text-sm py-2">
        La SPLF, très tôt, a proposé de créer des Réunions de Concertation Asthme , sur le modèle des RCA d’oncologie ou
        des DMD de Maladies Rares, comme un espace de discussion pour baliser l’ensemble des étapes préalables à la
        prescription d’une biothérapie : s’assurer du diagnostic de l’asthme, vérifier la prise en charge de l’ensemble
        des éléments aggravants (dépression, rhino-sinusite chronique, environnement…), définir les allergies, mettre en
        évidence une éosinophilie significative, identifier les facteurs prédictifs d’une bonne réponse à une
        biothérapie... Cette réflexion a ensuite été enrichie par la création d’une fiche récapitulative, disponible en
        ligne, crée par le groupe de travail Asthme et Allergie.
      </p>
      <p className="text-sm">
        L’application et le site Web « Guide Alizé » sont le prolongement naturel de cette réflexion de la SPLF sur une
        prescription raisonnée des biothérapies. Le guide ALIZE a été imaginé comme un outil d’aide à la décision
        pendant les RCA, dont il intègre d’ailleurs la fiche, et non comme un outil destiné à sauter l’étape de la RCA.
        Basé sur les libellés de l’HAS, il propose la et les biothérapies auxquels un patient peut être éligible sur les
        critères simples de l’allergie, le taux d’ éosinophiles ou de FeNO. A la RCA ensuite de faire son choix.
      </p>
      <p className="text-sm pt-2">
        Alize est un outil vivant, qui sera mis à jour tous les ans et suivra la progression des connaissances. On
        imagine ainsi que les récentes AMM obtenues récemment pour la polypose nasale peuvent influencer les
        prescriptions, avec des objectifs thérapeutiques qui vont changer. De même, on pourra peut-être intégrer un jour
        des propositions pour guider le choix du traitement en cas d’échec d’une première ligne.{' '}
      </p>
      <p className="text-sm">
        Enfin, ALIZE est fidèle aux objectifs pédagogiques de son grand frère ZEPHIR vis-à-vis d’une bonne
        administration des médicaments. Bénéficiant de l’immense expertise de l’équipe animée par Valéry Trosini-Désert
        depuis plusieurs années, ALIZE propose également en libre accès des vidéos de démonstration pour chacun des
        dispositifs, stylo ou seringue, d’injection des biothérapies, à destination de tous, patients, infirmiers,
        médecins. Saluons donc l’arrivée d’ALIZE et souhaitons-lui une belle et utile vie au service de la pneumologie !
      </p>

      <p className="text-blue-850 text-sm py-2">
        Pr Camille Taillé <br />
        Service de pneumologie <br /> Hôpital Bichat <br />
        Paris
      </p>
      <p className="text-black">
        <span className=" font-bold text-base ">Le comité scientifique guide Alizé</span>
        <br />
        <p className="font-semibold">Pr Camille Taillé</p> Pneumologue, PU-PH, Centre de l'Asthme et des Allergies,
        Service de Pneumologie, Hôpital Bichat-Claude Bernard, Assistance Publique - Hôpitaux de Paris.
        <p className="font-semibold">Dr Valéry Trosini Désert </p>
        Pneumologue, Praticien Hospitalier, Responsable de l’Unité Fonctionnelle d'endoscopie Bronchique du Service de
        Pneumologie , Groupe Hospitalier Pitié-Salpêtrière, Assistance Publique - Hôpitaux de Paris
      </p>
    </div>
  );
};

export default Editorial2022;
