function IcEternue({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="27.975" height="26.939" viewBox="0 0 27.975 26.939">
      <g id="coughing_alt" transform="translate(-6 -6)">
        <path
          id="Tracé_84141"
          data-name="Tracé 84141"
          d="M26.684,22.6H23.327v-.981h1.1a2.88,2.88,0,0,0,1.975-.772,2.589,2.589,0,0,0,.826-1.868V17.241c0,.072.453.03.67.009h0c.056-.005.1-.009.109-.009a.806.806,0,0,0,.493-.271.729.729,0,0,0,.176-.514,2.417,2.417,0,0,0-.286-.8L28,14.737c-.271-.634-.541-1.267-.8-1.916a20.4,20.4,0,0,0-.3-2.022,6.791,6.791,0,0,0-2.56-3.453,7.351,7.351,0,0,0-8.326-.105,6.744,6.744,0,0,0-2.583,3.274,6.371,6.371,0,0,0-.1,4.088,28.2,28.2,0,0,0,2.427,4.375V22.6H12.842A7.026,7.026,0,0,0,8.066,24.43,6.322,6.322,0,0,0,6,28.905v4.034H33.971V29.886a6.736,6.736,0,0,0-.482-2.737,7.021,7.021,0,0,0-1.557-2.35,7.457,7.457,0,0,0-2.391-1.6A7.79,7.79,0,0,0,26.684,22.6Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default IcEternue;
