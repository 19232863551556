import * as React from 'react';

function Password({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.479 15.105" {...props}>
      <defs>
        <style>{`.prefix__a_pwd{fill:${fill}}.prefix__b_pwd{fill:#111136}`}</style>
      </defs>
      <path
        className="prefix__a_pwd"
        d="M20.672 5.927a.65.65 0 00-.889-.238l-1.277.737V4.951a.65.65 0 10-1.3 0v1.475l-1.277-.737a.65.65 0 10-.65 1.126l1.277.738-1.277.738a.65.65 0 00.65 1.126l1.277-.737v1.475a.65.65 0 101.3 0V8.68l1.277.737a.65.65 0 10.65-1.126l-1.278-.738 1.278-.738a.65.65 0 00.238-.888zm0 0M11.762 5.927a.65.65 0 00-.889-.238l-1.277.737V4.951a.65.65 0 00-1.3 0v1.475l-1.277-.737a.65.65 0 10-.65 1.126l1.277.738-1.277.738a.65.65 0 00.65 1.126l1.277-.737v1.475a.65.65 0 001.3 0V8.68l1.277.737a.65.65 0 10.65-1.126l-1.277-.738 1.277-.738a.65.65 0 00.238-.888zm0 0M23.95 9.179a.651.651 0 00.889.238l1.277-.737v1.475a.65.65 0 001.3 0V8.68l1.277.737a.65.65 0 00.65-1.126l-1.277-.738 1.277-.738a.65.65 0 00-.65-1.126l-1.277.737V4.951a.65.65 0 00-1.3 0v1.475l-1.277-.737a.65.65 0 10-.65 1.126l1.277.738-1.277.738a.65.65 0 00-.238.888zm0 0"
      />
      <path
        className="prefix__b_pwd"
        d="M32.917-.004H21.451a.712.712 0 100 1.425h11.466a2.14 2.14 0 012.138 2.137v7.98a2.14 2.14 0 01-2.138 2.137H3.563a2.14 2.14 0 01-2.137-2.137v-7.98a2.14 2.14 0 012.137-2.137h11.475a.712.712 0 100-1.425H3.563A3.566 3.566 0 000 3.562v7.98a3.566 3.566 0 003.563 3.563h29.354a3.566 3.566 0 003.563-3.562v-7.98a3.566 3.566 0 00-3.563-3.567zm0 0"
      />
      <path
        className="prefix__b_pwd"
        d="M32.917-.004H21.451a.712.712 0 100 1.425h11.466a2.14 2.14 0 012.138 2.137v7.98a2.14 2.14 0 01-2.138 2.137H3.563a2.14 2.14 0 01-2.137-2.137v-7.98a2.14 2.14 0 012.137-2.137h11.475a.712.712 0 100-1.425H3.563A3.566 3.566 0 000 3.562v7.98a3.566 3.566 0 003.563 3.563h29.354a3.566 3.566 0 003.563-3.562v-7.98a3.566 3.566 0 00-3.563-3.567zm0 0"
      />
    </svg>
  );
}

export default Password;
