interface Props {
  display: string;
}
const Editorial2023 = ({ display }: Props) => {
  const DisplayCondition = 'Editorial';

  return (
    <div id="editorial" style={{ display: display === DisplayCondition ? 'block' : 'none' }} className="font-regular">
      <p className="text-sm pt-3">
        L’arrivée des anticorps monoclonaux a bouleversé la prise en charge de l’asthme sévère depuis quelques années.
        La multiplication des molécules disponibles, actuellement 5 et probablement d’autres à venir, a complexifié au
        fur et à mesure la stratégie thérapeutique. Si les libellés de la Commission de Transparence posent les
        premières bases pour guider le choix du médecin, celui-ci se heurte vite au fait que les indications se
        recoupent souvent, puisque c’est bien la même population de patients dont l’inflammation bronchique est dominée
        par les éosinophiles et les cytokines de type 2 qui est ciblée par les molécules actuellement disponibles. Aucun
        essai randomisé n’a comparé directement deux molécules, ce qui rend toute recommandation privilégiant une
        molécule par rapport à une autre, impossible pour le moment. Alors comment faire ?
      </p>
      <p className="text-sm py-2">
        La SPLF, très tôt, a proposé de créer des Réunions de Concertation Asthme, sur le modèle des RCA d’oncologie ou
        des DMD de Maladies Rares, comme un espace de discussion pour baliser l’ensemble des étapes préalables à la
        prescription d’une biothérapie : s’assurer du diagnostic de l’asthme, vérifier la prise en charge de l’ensemble
        des éléments aggravants (dépression, rhino-sinusite chronique, environnement…), définir les allergies, mettre en
        évidence une éosinophilie significative, identifier les facteurs prédictifs d’une bonne réponse à une
        biothérapie... Cette réflexion a ensuite été enrichie par la création d’une fiche récapitulative, disponible en
        ligne, créée par le groupe de travail Asthme et Allergie.
      </p>
      <p className="text-sm">
        L’application et le site Web « Guide Alizé » sont le prolongement de cette réflexion de la SPLF sur la bonne
        utilisation des médicaments. Fidèles aux objectifs pédagogiques de son grand frère ZEPHIR, et bénéficiant de
        l’immense expertise de l’équipe animée par Valéry Trosini-Désert depuis plusieurs années, ALIZE propose en libre
        accès des fiches explicatives pour chaque molécule et des vidéos de démonstration pour chacun des dispositifs,
        stylo ou seringue, d’injection des biothérapies, à destination de tous, patients, infirmiers, médecins.
        Souhaitons au Guide Alizé une belle et utile vie au service de la pneumologie!
      </p>

      <p className="text-blue-850 text-sm py-2">
        Pr Camille Taillé <br />
        Service de pneumologie <br /> Hôpital Bichat <br />
        Paris
      </p>
      <p className="text-black">
        <span className=" font-bold text-base ">Le comité scientifique guide Alizé</span>
        <br />
        <p className="font-semibold">Pr Camille Taillé</p> Pneumologue, PU-PH, Centre de l'Asthme et des Allergies,
        Service de Pneumologie, Hôpital Bichat-Claude Bernard, Assistance Publique - Hôpitaux de Paris.
        <p className="font-semibold">Dr Valéry Trosini Désert </p>
        Pneumologue, Praticien Hospitalier, Responsable de l’Unité Fonctionnelle d'endoscopie Bronchique du Service de
        Pneumologie , Groupe Hospitalier Pitié-Salpêtrière, Assistance Publique - Hôpitaux de Paris
      </p>
    </div>
  );
};

export default Editorial2023;
