import { MutationHookOptions, QueryHookOptions, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useLocalLazyQuery, useLocalMutation, useLocalQuery } from 'hooks/apollo';

export interface Favorite {
  id: string;
  medicine: {
    id: string;
    name: string;
    image: string;
    lab: string;
    dci: {
      name: string;
    };
  };
}

export const favoritesQuery = gql`
  query getFavorisByUser($perPage: Int, $page: Int) {
    favorites(perPage: $perPage, page: $page) {
      count
      page
      totalPages
      data {
        id
        medicine {
          id
          name
          image
          lab

          dci {
            name
          }
        }
      }
    }
  }
`;

export interface FavoritesData {
  count: number;
  page: number;
  totalPages: number;
  data: Favorite[];
}

export const useFavorites = (options: QueryHookOptions<{ favorites: FavoritesData }, {}> = {}) =>
  useLocalQuery(favoritesQuery, options);
export const useLazyFavorites = (options: QueryHookOptions<{ favorites: FavoritesData }, {}> = {}) =>
  useLocalLazyQuery(favoritesQuery, options);

export const removeFavouriteQuery = gql`
  mutation removeFavorites($id: ID) {
    removeFavorites(id: $id)
  }
`;

interface RemoveArgs {
  id?: string;
}

export const useDeleteFavorite = (options: MutationHookOptions<{ removeFavorites: RemoveArgs }, {}> = {}) =>
  useLocalMutation(removeFavouriteQuery, options);

export const favoriteQuery = gql`
  query Favorite($medicine: ID!) {
    favorite(medicine: $medicine) {
      id
      medicine {
        id
        name
        image
        lab
      }
    }
  }
`;

export const useFavorite = (options: MutationHookOptions<{ favorite: Favorite }, { medicine: string }> = {}) =>
  useLazyQuery(favoriteQuery, options);
