import gql from 'graphql-tag';

import { QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery } from 'hooks/apollo';

export const dcisQuery = gql`
  query dcis($name: String) {
    dcis(name: $name, sort: "name", order: 1) {
      count
      page
      data {
        id
        name
      }
    }
  }
`;

export interface DCISData {
  count: number;
  page: number;
  data: {
    id: string;
    name: string;
  }[];
}

export const useDCIS = (options: QueryHookOptions<{ dcis: DCISData }, {}> = {}) => useLocalQuery(dcisQuery, options);
export const useLazyDCIS = (options: QueryHookOptions<{ dcis: DCISData }, {}> = {}) =>
  useLocalLazyQuery(dcisQuery, options);

export const dciQuery = gql`
  query dci($id: ID!) {
    dci(id: $id) {
      id
      name
    }
  }
`;

export interface DCIData {
  id: string;
  name: string;
}

export const useDCI = (options: QueryHookOptions<{ dci: DCIData }, {}> = {}) => useLocalQuery(dciQuery, options);
