// import astra from 'assets/png/AstraZeneca.png';
// import gsk from 'assets/png/gsk.png';
import sano from 'assets/svg/sanofiLogo.svg';
import AstraZeneca from 'assets/svg/AstraZeneca_Q.svg';

import { FC } from 'react';
import classNames from 'utils/classNames';
import classes from './sponsor.module.scss';

export const Sponsor: FC<React.HTMLProps<HTMLElement>> = () => {
  const images = [
    // { img: astra, className: 'mb-2 2xl:mb-4 h-8 lg:h-10 sm:h-7' },
    // { img: chiesi, className: 'h-10  sm:h-7 mr-2' },
    // { img: gsk, className: 'h-7 sm:h-5 mt-1 sm:mt-2 sm:mb-2' },
    { img: AstraZeneca, className: 'h-14   pb-2 sm:h-10  ' },

    { img: sano, className: 'h-14  sm:h-9' },
  ];
  return (
    <div
      className={classNames(
        'grid sm:block grid-flow-col sm:text-center',
        'h-16 lg:pl-2.5 lg:pr-1 sm:px-0 py-2 2xl:h-10 sm:h-auto ',
        'rounded-5 bg-white lg:content-center w-190  items-center content-center',
      )}
    >
      <div className="font-medium text-sm flex  gap-1 sm:pl-1  sm:text-xs text-grey-500 pr-2 sm:pr-0 pt-1">
        Avec le soutien institutionnel des Laboratoires
      </div>
      <div className="flex  lg:h-full gap-1 lg:gap-0 lg:grid sm:flex  sm:justify-center  lg:grid-flow-col justify-self-center w-full items-center">
        {images.map(({ img, className }, i) => (
          // eslint-disable-next-line
          <img key={i} className={classNames(`object-contain    ${className}`, classes.image)} src={img} alt="" />
        ))}
      </div>
    </div>
  );
};
