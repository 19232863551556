import gql from 'graphql-tag';

import { QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery } from 'hooks/apollo';

export const questionsQuery = gql`
  query {
    questionss(sort: "_id") {
      data {
        question
        possibleAnswers
      }
    }
  }
`;

export interface QUESTIONSData {
  count: number;
  page: number;
  data: {
    question: string;
    possibleAnswers: string[];
  }[];
}

export const useQuestions = (options: QueryHookOptions<{ questionss: QUESTIONSData }, {}> = {}) =>
  useLocalQuery(questionsQuery, options);
export const useLazyQuestions = (options: QueryHookOptions<{ questionss: QUESTIONSData }, {}> = {}) =>
  useLocalLazyQuery(questionsQuery, options);
