import Button from 'components/Button';
import FavoriteFilled from 'components/icons/FavoriteFilled';
import Plus from 'components/icons/Plus';
import DeleteModal from 'components/ux/DeleteModal';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteFavorite } from 'requests/Favoris';
import classNames from 'utils/classNames';
import Trash from '../../../assets/svg/corbeille.svg';
import classes from './FavoriteCard.module.scss';

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  footer: string;
  medicinePhoto: string;
  medicineId: string;
  FavoriteID: string;
  refetch: any;
  smallTitle: string;
}

const Card = ({
  title,
  refetch,
  medicineId,
  FavoriteID,
  medicinePhoto,
  footer,
  className,
  smallTitle,
  ...rest
}: CardProps) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <div
      id={`id_${FavoriteID}`}
      className={classNames(
        'flex flex-col text-blue-600 hover:text-white bg-white rounded-md hover:border-green h-full',
        ' hover:bg-blue-950 relative',
        classes.container,
        className,
      )}
    >
      <DeleteModal
        deleteCall={useDeleteFavorite}
        id={open ? FavoriteID : ''}
        msg="Êtes-vous sûr de vouloir supprimer ce médicament de la liste de vos favoris&nbsp;?"
        confirmationMsg="Ce médicament a été supprimé de la liste de vos favoris avec succès"
        onClose={(updated: boolean) => {
          setOpen(false);
          if (updated) {
            refetch();
          }
        }}
      />

      <div {...rest} className="p-2 flex-1">
        <div className={classNames('h-60 flex items-center  justify-center rounded-md', classes.video)}>
          <img alt="" src={medicinePhoto} className="h-60 w-full object-contain" />
        </div>

        <div className={classNames('gap-3 flex-col items-center justify-center hidden h-60 rounded-md', classes.btns)}>
          <Button
            onClick={() => {
              history.push(`/medicament/${medicineId}?from=favoris`);
            }}
            className="mt-20  border-none hover:bg-white"
            variant="tertiary"
            size="md"
          >
            <div className="flex items-center justify-center gap-5 w-full">
              <div className="w-6">
                <Plus height={24} />
              </div>
              <div className="w-28 xl:text-sm "> Voir détails</div>
            </div>
          </Button>
          <div className="cursor-pointer" onClick={() => setOpen(true)}>
            <p
              className={classNames(
                'text-xs rounded-md rounded-r-none rounded-br-none absolute p-1 text-white',
                'left-6 top-3 h-7 underline text-blue-200 ',
              )}
            >
              Supprimer
            </p>
            <img
              src={Trash}
              alt="trashh"
              className="rounded-md rounded-r-none rounded-br-none absolute p-1 text-white pr-3 left-1  top-3 h-7 "
            />
          </div>
        </div>

        <div className="text-base px-4 py-2 pb-5">
          <div className="font-bold">{title}</div>

          <div className="justify-between	flex">
            <div className="italic ">{smallTitle?.replace(/^./, smallTitle[0]?.toUpperCase())}</div>
          </div>
        </div>
      </div>
      <div className={classNames(' w-full ')}>
        <div className={classNames('flex px-6 pt-2 pb-3 ', classes.footer)}>
          <span className={classNames('text-blue-750 ', classes.lab)}> {footer} </span>
          <div className={classNames('text-green flex flex-1 justify-end items-center footer ', classes.footer)}>
            <FavoriteFilled height={13} />
            <div className="pl-2.5">Mes Favoris</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
