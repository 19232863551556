import Button from 'components/Button';
import Plus from 'components/icons/Plus';
import Pologone from 'components/icons/Polygone';
import cardIdContext from 'contexts/CardIdContext';
import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import ModalVideo from '../../ux/ModalVideo/ModalVideo';
import classes from './Card.module.scss';

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  footer?: string;
  medicineId?: string;
  dci?: string;
  deviceType?: string;
  medicinePhoto?: string;
  from?: string;
  typeVideoOpen?: (open: boolean) => void;
  videoData?: { seringue: string; stylo: string; QRseringue: string; QRstylo: string; url: string; qrCode: string };
  medidineDescription?: { classe: string; dci: string; device: string; dosage: string };
  classNameButton?: string;
  details?: boolean;
}

const Card: React.FC<CardProps> = ({
  title,
  medicineId,
  footer,
  className,
  medicinePhoto,
  deviceType,
  from,
  dci,
  typeVideoOpen,
  videoData = { seringue: '', stylo: '', url: '', qrCode: 'qrTest' },
  medidineDescription,
  classNameButton,
  details,
  ...rest
}) => {
  const location = useLocation();
  const [videoModal, setvideoModal] = useState(false);
  const { setCardId } = useContext(cardIdContext);
  const [videoLink, setvideoLink] = useState('');
  const [QRLink, setQRLink] = useState('');
  return (
    <div
      id={medicineId}
      className={classNames(
        `flex flex-col text-blue-600 hover:text-white bg-white rounded-5 w-full`,
        'hover:border-green border border-grey hover:bg-green ',
        classes.container,
        !location.pathname.includes('/indications') ? classes.containerAdjusted : classes.containerAdjustedMin,
        className,
      )}
    >
      <div
        {...rest}
        className={classNames('flex-1', classes.backgroundImage)}
        style={{
          backgroundImage: `url(${medicinePhoto})`,
        }}
      >
        <div className={classNames('relative', classes.video)}>
          {/* eslint-disable-next-line */}
          <img alt="fdsf" src={medicinePhoto} className="object-contain w-auto h-275 hidden" />
        </div>

        <div className={classNames('gap-3  flex-col items-center justify-center hidden h-60 ', classes.btns)}>
          <Link
            target={from === 'question' ? '_blank' : '_parent'}
            to={{
              pathname: `/medicament/${medicineId}`,
              search: from ? `${location.search}&from=${from}` : location.search,
            }}
            className="w-9/12"
          >
            <Button
              className=" w-full text-left"
              onClick={() => {
                setCardId(medicineId || '');
              }}
              variant="quaternary"
              size="sm"
            >
              <div className="flex items-center  gap-1 w-full px-4">
                <div className="w-6">
                  <Plus height={20} width={17} />
                </div>

                <div className={classNames(`w-28 xl:text-sm`, classNameButton)}> Voir détails</div>
              </div>
            </Button>
          </Link>
          {!!videoData?.seringue.length && (
            <button
              className=" font-bold w-9/12 flex items-center text-left gap-3 bg-green  hover:bg-white hover:text-green text-grey-100 hover:outline-none font-medium border border-white h-10 rounded text-sm"
              onClick={() => {
                setvideoModal(true);
                setvideoLink(`${videoData?.seringue}23`);
                setQRLink(videoData?.QRseringue || '');
                if (typeVideoOpen) typeVideoOpen(false);
              }}
            >
              <Pologone height={40} width={20} className="mt-1 ml-4  h-14 flex items-center" />
              Seringue pré-remplie
            </button>
          )}
          {!!videoData.stylo && (
            <button
              className="font-bold w-9/12 flex items-center text-left gap-3 bg-green hover:bg-white hover:text-green text-grey-100 hover:outline-none font-medium border border-white h-10 rounded text-sm"
              onClick={() => {
                setvideoModal(true);
                setvideoLink(`${videoData.stylo}23`);
                setQRLink(videoData.QRstylo || '');
                if (typeVideoOpen) typeVideoOpen(true);
              }}
            >
              <Pologone height={40} width={20} className="mt-1 ml-4  h-14 flex items-center" />
              Stylo pré-rempli
            </button>
          )}

          <ModalVideo
            from={from}
            onClose={() => setvideoModal(false)}
            medidineDescription={medidineDescription}
            title={title}
            deviceType={`${title} ${videoData.QRstylo === QRLink ? 'en Stylo pré-rempli' : 'en Seringue pré-remplie'}`}
            medicineId={medicineId}
            videoData={{ url: videoLink, qrCode: QRLink }}
            open={videoModal}
            detail={details}
          />
        </div>
      </div>
      <div>
        <div className="text-base px-3 py-2 pb-0">
          <div className="font-bold">
            {' '}
            {title?.split('®').map((e: any, index: number, array: any) => (
              <>
                {e}
                {array.length > 1 && index !== array.length - 1 && <span className="text-3xl">®</span>}
              </>
            ))}
          </div>
          {true && <div className="italic">{dci?.replace(/^./, dci[0].toUpperCase())}</div>}
        </div>
        <div className=" w-full">
          <div className="flex font-medium px-3 pt-1 pb-3">
            <div
              className={classNames(
                'flex-1 mr-2 text-blue-950 text-xs 3_5xl:text-lg pt-3',
                footer?.trim().split(' ').length === 1 && 'truncate',
                classes.footer,
              )}
            >
              {footer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
