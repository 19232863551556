import logoAlize from 'assets/svg/logoAlizeWhite.svg';
import cardIdContext from 'contexts/CardIdContext';
import React, { useContext, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import About from './About';
import HeaderLink from './HeaderLink';
import Medecines from './Medecines/Medecines';
import Menu from './Menu';

const list = [
  { title: 'Asthme sévère', path: '/asthmeSevere' },
  { title: 'Comment discuter le traitement en RCA ?', path: '/indications', text: '(réunion de concertation asthme)' },
];

const WebMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const { setCardId } = useContext(cardIdContext);
  const [hoverdTitle, setHoveredTitle] = useState('');
  const cursorChangeHandler = (index: string) => {
    setHoveredTitle(index);
  };
  return (
    <header
      className={classNames(
        'h-32 self-center	grid grid-flow-col items-center w-full 2xl:px-26 grid-cols-469  3xl:grid 2xl:grid xl:grid lg:hidden md:hidden sm:hidden px-28 lg:px-5 lg:pr-0 2xl:grid-cols-600 lg:grid-cols-601 pr-0',
      )}
    >
      <div className="h-full grid items-center w-12/12 py-2">
        {/* eslint-disable-next-line */}
        <img
          className="cursor-pointer h-16 xl:h-12 md:h-12 sm:h-12"
          src={logoAlize}
          alt=""
          onClick={() => {
            history.push('/');
            setCardId('');
          }}
        />
        <div className="text-blue-650 text-xs sm:text-10 md:text-10 lg:text-10 xl:text-10 sm:w-4/5	">
          Guide des biothérapies dans l’asthme sévère chez l’adulte
        </div>
      </div>
      <div className="flex h-full items-stretch flex-0.7 lg:flex-0.8">
        <Medecines />
        {list.map((l) => {
          return (
            <HeaderLink
              selected={!!matchPath(location.pathname, l.path)}
              onClick={() => history.push(l?.path)}
              key={l.title}
              onMouseEnter={() => cursorChangeHandler(l.title)}
              onMouseLeave={() => cursorChangeHandler('')}
            >
              <div className="flex flex-col">
                <div className="pb-2">{l.title}</div>
                {hoverdTitle === 'Comment discuter le traitement en RCA ?' ? (
                  <div className="text10">{l.text}</div>
                ) : null}
              </div>
            </HeaderLink>
          );
        })}
        <About />

        <Menu />
      </div>
    </header>
  );
};

export default WebMenu;
