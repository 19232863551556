import React, { useState, useRef, useEffect } from 'react';

import PreviewIcon from 'components/icons/Preview';
import ModalImage from './ModalImage';

export default function ImagePreview({ ...props }) {
  const { src } = props;
  const [open, setOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const mouseMoveRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkHover = (e: MouseEvent) => {
      if (mouseMoveRef.current) {
        const mouseOver = mouseMoveRef.current.contains(e.target as Node);
        setIsHovering(mouseOver);
      }
    };

    window.addEventListener('mousemove', checkHover, true);

    return () => {
      window.removeEventListener('mousemove', checkHover, true);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      className="relative rounded-lg mt-3  "
      ref={mouseMoveRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovering ? (
        <div
          className="rounded-lg absolute w-full h-full flex items-center justify-center"
          style={{ boxShadow: 'inset 13px 18px 192px 93px rgb(0 0 0 / 70%)' }}
        >
          <div
            className=" cursor-pointer  flex justify-center items-center"
            onClick={() => {
              setOpen(true);
              setIsHovering(false);
            }}
          >
            <PreviewIcon fill="white" className=" cursor-pointer hover:h-8" height={25} />
            {/* eslint-disable-next-line */}
            <p className="ml-1 text-sm underline cursor-pointer text-white font-bold">Agrandir l'image</p>
          </div>
        </div>
      ) : null}
      <ModalImage
        open={open}
        onClose={() => {
          setOpen(false);
          setIsHovering(false);
        }}
      >
        <img src={src} alt=" " className="w-full h-full" />
      </ModalImage>
      <img alt="dssd" {...props} />
    </div>
  );
}
