import { QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { useLocalLazyQuery, useLocalQuery } from 'hooks/apollo';
import { Medicine } from './medicaments';

export const answersQuery = gql`
  query answerss($page: Int, $Q1: String, $Q2: String, $Q3: String, $Q4: String, $Q5: String) {
    answerss(page: $page, Q1: $Q1, Q2: $Q2, Q3: $Q3, Q4: $Q4, Q5: $Q5) {
      data {
        id
        reponse
        remarque
        medsId {
          id
          name
          classeFull
          image
          lab
          dci {
            name
          }
          classe {
            name
          }
          video {
            url
            qrCode
            stylo
            QRstylo
            QRseringue
            seringue
          }
        }
      }
    }
  }
`;

export interface ANSWERSData {
  count: number;
  page: number;
  data: {
    id: string;
    reponse: string;
    remarque: string;
    medsId: Medicine[];
  }[];
}

export const useAnswers = (options: QueryHookOptions<{ answerss: ANSWERSData }, {}> = {}) =>
  useLocalQuery(answersQuery, options);
export const useLazyAnswers = (options: QueryHookOptions<{ answerss: ANSWERSData }, {}> = {}) =>
  useLocalLazyQuery(answersQuery, options);
