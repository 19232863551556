import { useMedicine } from 'requests/medicaments';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Indication = () => {
  const { data } = useMedicine({ fetchPolicy: 'no-cache' });
  return (
    <MedecineMenu
      data={data?.medicines.data}
      count={false}
      pathname="/"
      sortFunction={(a, b) => a.name.localeCompare(b.name)}
      title="Médicaments"
    />
  );
};

export default Indication;
