import logo from 'assets/svg/logoAlizeWhite.svg';
import { Sponsor } from 'components/ux/sponsor';
import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import classes from './Footer.module.scss';
import FooterLink from './FooterLink';

const menu = [
  { title: 'Médicaments', pathname: '/' },
  { title: 'Asthme sévère', pathname: '/asthmeSevere' },
  { title: 'Comment discuter le traitement en RCA ?', pathname: '/indications' },
  { title: 'Mes notes', pathname: '/notes' },
  { title: 'Mes favoris', pathname: '/favoris' },
];
const other = [
  { title: 'Auteurs', pathname: '/auteurs' },
  { title: 'Éditorial', pathname: '/informations' },
];
const Footer = () => {
  const location = useLocation();
  return (
    <>
      {!location.pathname.includes('/auteurs') && !location.pathname.includes('/404') && (
        <div className={classNames(classes.icons, 'flex bg-white sm:justify-center ')}>
          <div className="w-full flex  sm:px-5 lg:px-16 px-28 justify-between  sm:justify-center">
            <Sponsor />
          </div>
        </div>
      )}
      <footer
        className={classNames(
          'min-h-96 flex  2xl:px-26 px-26 py-12 lg:px-20 sm:px-5  px-28 bg-grey-900 sm:mt-4',
          classes.container,
        )}
      >
        <div className={classNames('sm:px-0 pr-24 2xl:pr-10  lg:w-full ', classes.logo)}>
          <img src={logo} alt="" width={133} className="lg:pb-2" />
          <div className="text-blue-600  text-sm pt-5 2xl:text-xs 2xl:pt-2.5  3xl:w-10/12 4xl:w-10/12">
            Guide des biothérapies dans l’asthme sévère chez l’adulte
          </div>
        </div>
        <div className="sm:px-0 pr-10  contents justify-around ">
          <div className={classNames(classes.menu, 'pb-3 pr-24 2xl:pr-16 lg:pr-0')}>
            <FooterLink title="Menu" data={menu} />
          </div>
          <div className={classNames(classes.other, 'pb-3')}>
            <FooterLink title="Autres" data={other} />
          </div>
        </div>
      </footer>
      <div className="text-blue-600 text-xs flex justify-center items-center h-10 bg-grey-800 lg:bg-green lg:text-white">
        Copyright {new Date().getFullYear()} SPLF.
        <span className="underline pl-1"> Mentions légales</span>
      </div>
    </>
  );
};
export default Footer;
