import pdfIcon from 'assets/svg/pdf.svg';
import Search from 'components/common/Search/Search';
import ZephirDescription from 'components/common/ZephirDescription';
import Bonhomme from 'components/icons/IcBonhomme';
import Eternue from 'components/icons/IcEternue';
import Livre from 'components/icons/IcLivre';
import Sang from 'components/icons/IcSang';
import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import { decodeUri } from 'utils/url';
import RightArrowWhite from '../../assets/svg/arrowRightWhite.svg';
import DownRow from '../../assets/svg/downRow.svg';
import RightArrow from '../../assets/svg/rightArrowGray.svg';
import classes2 from '../HomeGeneric/HomeGeneric.module.scss';
import classes from './AsthmaSevere.module.scss';

export const PER_PAGE = 100;
const profilItems = [
  {
    icon: <Eternue className="ml-2 " />,
    btnTitle: 'Asthme allergique',
    type: <b>Asthme allergique</b>,
    clinique: (
      <div className="flex flex-col ">
        <span>Asthme sévère </span>
        <span>et manifestation d' asthme en présence d'un allergène</span>
      </div>
    ),
    marque: 'Confirmation de la sensibilisation par prick test et /ou IgE spécifique',
  },
  {
    icon: <Sang className="ml-2 " />,
    btnTitle: 'Asthme à éosinophiles',
    type: <b>Asthme à éosinophiles</b>,
    clinique: (
      <div className="flex flex-col ">
        <span>Asthme sévère. </span>
        <span>
          Une rhinosinusite chronique est souvent associée. L'asthme allergique est souvent associé à une éosinophilie.
        </span>
      </div>
    ),
    marque: "Taux d'éosinophiles sanguins dans les 12 mois >150/mm3",
  },
  {
    icon: <Livre className="ml-2 " />,
    btnTitle: 'Asthme de type T2',
    type: <b>Asthme de type T2</b>,
    clinique:
      "L'inflammation T2 est définie notamment par la production de cytokines type IL4, IL5 ou IL13 par les lymphocytes Th2 et les ILC2. La définition regroupe l'asthme sévère allergique, à éosinophiles , avec élévation du FENO, les 3 critères pouvant être éventuellement associés.",
    marque: (
      <div className="flex flex-col">
        <div>Confirmation de la sensibilisation par prick test et /ou IgE spécifique</div>
        <div>Taux d'éosinophiles sanguin dans les 12 mois {'>'}150/mm3</div>
        <div>Mesure du FENO {'>'}20 ppb</div>
      </div>
    ),
  },
  {
    icon: <Bonhomme className="ml-2 " />,
    btnTitle: 'Asthme non allergique non éosinophilique',
    type: (
      <div className="flex flex-col ">
        <b>Asthme non allergique non éosinophilique</b>
        <span>Quatrième profil dit "non-T2" qui est non accessible aux biothérapies actuellement disponibles.</span>
      </div>
    ),
  },
];

interface Props {
  filterComponent: React.ReactNode;
  typeFilter?: string;
}

const AsthmaSevere: React.FC<Props> = () => {
  const location = useLocation();
  const uri = decodeUri(location.search);
  const [responsive, setresponsive] = useState(window.innerWidth < 1000);
  const { setMenuStates } = useContext(MenuContext);
  const [cursorType, setCursorType] = useState<boolean>(false);
  const [indexBtn, setIndexBtn] = useState<number>(0);
  const changeIndex = (index: number, cursor: boolean) => {
    setIndexBtn(index);
    setCursorType(cursor);
    if (indexBtn !== index) {
      setCursorType(true);
    }
  };

  useEffect(() => {
    if (uri.medId) {
      const el = document.getElementById(uri.medId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, uri]);

  const checkResponsive = () => {
    if (window.innerWidth < 1000) {
      setresponsive(true);
    } else {
      setMenuStates(MenuStatesValues.CLOSE);
      setresponsive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', checkResponsive);
    return () => {
      window.removeEventListener('resize', checkResponsive);
    };
  }, []);
  return (
    <>
      <Search />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div
        className={classNames(
          'bg-grey-200 px-28 py-12 lg:px-0 md:px-5 sm:px-5 sm:py-1 ',
          !responsive ? classes2.container2 : classes.container,
        )}
      >
        <div>
          <div
            className={classNames(
              'text-grey-400 text-2xl font-bold  mb-12 sm:mt-8 sm:mb-8 text-center ',
              classes.title,
            )}
          >
            Les différents types d’asthme sévère
          </div>
          <div className="flex flex-col justify-center items-center ">
            <div className=" bg-grey-300 sm:bg-transparent flex items-center justify-center py-6 sm:w-275  lg:w-full xl:w-1050 2xl:w-1140 3xl:w-1450 4xl:w-1450 sm:mb-0	 mb-10 lg:min-h-100 min-h-400">
              <div className="float-left flex flex-col">
                {profilItems.map((item, index) => (
                  <>
                    <button
                      className={
                        indexBtn !== index
                          ? 'sm:w-80    w-96 h-14  bg-grey-350  flex items-center justify-between	 '
                          : 'sm:w-80    w-96 h-14  text-white  bg-green flex items-center justify-between	 '
                      }
                      onClick={() => changeIndex(index, !cursorType)}
                    >
                      <div className="flex justify-center items-center">
                        {item.icon}
                        <span
                          className={
                            indexBtn !== index
                              ? 'text-blue-600 float-left ml-6 h-14 flex items-center text-left	'
                              : 'text-white   float-left ml-6 h-14 flex items-center text-left	'
                          }
                        >
                          {item?.btnTitle}
                        </span>
                      </div>
                      {indexBtn === index ? (
                        <>
                          {cursorType ? (
                            <img
                              width="20"
                              height="50"
                              alt=""
                              src={DownRow}
                              className="float-right mr-4 hidden lg:block h-14 flex items-center"
                            />
                          ) : (
                            <img
                              height="50"
                              width="10"
                              alt=""
                              src={RightArrow}
                              className="float-right mr-4 lg:block hidden h-14 flex items-center"
                            />
                          )}
                          <img
                            width="10"
                            height="20"
                            alt=""
                            src={RightArrowWhite}
                            className=" float-right mr-4 lg:hidden h-14 flex items-center"
                          />
                        </>
                      ) : (
                        <img
                          height="50"
                          width="10"
                          alt=""
                          src={RightArrow}
                          className="mt-1 float-right mr-4 h-14 flex items-center"
                        />
                      )}
                    </button>
                    <div
                      className={
                        cursorType && indexBtn === index
                          ? 'hidden lg:block sm:w-80    w-96 px-6 py-4 2xl:h-64  lg:h-auto flex flex-col mb-4'
                          : 'hidden'
                      }
                    >
                      <div className="flex flex-col gap-2 mb-4">
                        <span className="font-semibold">Type: </span>
                        {item?.type}
                      </div>
                      <div className={item?.clinique !== undefined ? 'flex flex-col gap-2 mb-4' : 'hidden'}>
                        <span className="font-semibold">Clinique: </span> {item?.clinique}
                      </div>
                      <div className={item?.marque !== undefined ? 'flex flex-col gap-2 mb-4' : 'hidden'}>
                        {' '}
                        <span className="flex flex-rows">
                          <span className="font-semibold">Marqueurs</span>
                          <span>:</span>
                        </span>{' '}
                        {item?.marque}
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="lg:hidden w-2/4 md:w-3/4 lg:w-3/4">
                {profilItems.map((item, index) => (
                  <div
                    className={
                      indexBtn === index ? ' px-6 py-4 flex flex-col mb-4 md:h-300 xl:h-300  h-256 ' : 'hidden'
                    }
                  >
                    <div className="flex flex-rows gap-16 mb-4">
                      <span className="flex flex-rows gap-1 font-semibold">
                        <span>Type</span>
                        <span>:</span>{' '}
                      </span>
                      {item?.type}
                    </div>
                    <div className={item?.clinique !== undefined ? 'flex flex-rows gap-11 mb-4' : 'hidden'}>
                      <span className="flex flex-rows gap-1 font-semibold">
                        <span>Clinique</span>
                        <span>:</span>{' '}
                      </span>
                      {item?.clinique}
                    </div>
                    <div className={item?.marque !== undefined ? 'flex flex-rows gap-4 mb-4' : 'hidden'}>
                      <span className="flex flex-rows gap-1 font-semibold">
                        <span>Marqueurs</span>
                        <span>:</span>
                      </span>{' '}
                      {item?.marque}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <ul className=" list-disc ">
            <div className="font-bold pb-2">Bibliographie </div>

            <li>
              <div className="flex gap-5 pb-4 justify-start sm:flex-col">
                Recommandations SPLF :{' '}
                <a
                  href="https://api-guide-alize.wereact.tech/uploads/pdf/splf_reco.pdf"
                  target="_blank"
                  download
                  className="underline flex flex-row gap-1"
                  rel="noreferrer"
                >
                  <img src={pdfIcon} alt="" className="h-6 sm:w-6 " />
                  Télécharger le PDF
                </a>
              </div>
            </li>
            <li>
              <div className="flex gap-4 pb-4 sm:flex-col">
                Recommandations GINA :{' '}
                <a
                  href="https://ginasthma.org/wp-content/uploads/2019/04/GINA-Severe-asthma-Pocket-Guide-v2.0-wms-1.pdf"
                  target="_blank"
                  download
                  className="underline flex flex-row gap-1"
                  rel="noreferrer"
                >
                  <img src={pdfIcon} alt="" className="h-6 sm:w-6 " />
                  Télécharger le PDF
                </a>
              </div>
            </li>
            <li>
              <div className="flex gap-2 pb-4 sm:flex-col">
                Recommandations EAACI :{' '}
                <a
                  href="https://onlinelibrary.wiley.com/doi/epdf/10.1111/all.14425"
                  target="_blank"
                  download
                  className="underline flex flex-row gap-1"
                  rel="noreferrer"
                >
                  <img src={pdfIcon} alt="" className="h-6 sm:w-6 " />
                  Télécharger le PDF
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AsthmaSevere;
