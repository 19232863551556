import Up from 'assets/svg/goUp.svg';
import VideoCard from 'components/common/VideoCard/VideoCard';
import ZephirDescription from 'components/common/ZephirDescription';
import FilterAlpha from 'components/ux/FilterAlphabetical';
import { PER_PAGE } from 'containers/HomeGeneric/HomeGeneric';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyMedicines } from 'requests/medicaments';
import classNames from 'utils/classNames';
import { Spinner } from 'utils/loading';
import { decodeUri, encodeUri } from 'utils/url';
import HeaderRouter from '../DetailsMedication/Components/HeaderRouter';

const PageVideos = () => {
  const location = useLocation();
  const history = useHistory();
  const [readSectionActive, setreadSectionActive] = useState(false);
  const uri = decodeUri(location.search);
  const [dataCall, { data, loading: videoLoading }] = useLazyMedicines({
    fetchPolicy: 'no-cache',
  });

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  useEffect(() => {
    dataCall({
      variables: {
        perPage: PER_PAGE,
        ...uri,
        page: uri.page ? Number(uri.page) : 1,
        sort: 'name',
        order: uri?.order === '-1' ? -1 : 1,
      },
    });
  }, [uri.page, uri.order, uri.start]);

  <div className="absolute top-1/3 right-1/2">
    <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
  </div>;

  useEffect(() => {
    if (uri.videoId) {
      const el = document.getElementById(uri.videoId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, uri]);
  return (
    <>
      <div className="lg:h-40 h-16 sm:h-36">
        <HeaderRouter
          onClick={() => {
            history.push('/');
          }}
          subTitle="Vidéos éducatives"
          pageTitle="Médicaments"
        />
      </div>
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className={classNames('bg-grey-200 p-16 px-28 lg:px-8 md:px-5 sm:px-5 w-full')}>
        <div>
          <div className="flex w-full justify-between  pb-4 lg:flex-col ">
            <div className="text-grey-400 text-32 2xl:text-26 lg:text-22 font-bold">Vidéos éducatives</div>
            <div className={classNames('text-sm 2xl:text-xs flex items-center pt-3 lg:pt-0 pr-0.5')}>
              <div className="text-green font-semibold">
                {data?.medicines.data.reduce((count, e) => {
                  let counnt = count;
                  if (e?.video?.stylo) counnt += 1;
                  if (e?.video?.seringue) counnt += 1;
                  return counnt;
                }, 0)}
              </div>
              <div className="text-blue-600  font-normal pl-1 ">
                Résultat{data?.medicines?.count! > 1 ? 's' : ''} trouvé{data?.medicines?.count! > 1 ? 's' : ''}
              </div>
            </div>
          </div>
          <FilterAlpha
            uri={uri}
            filterAllDatas={() => {
              const { start, ...newUri } = uri;
              return history.replace({ pathname: location.pathname, search: encodeUri(newUri) });
            }}
            filterdDatas={(i) => {
              history.replace({ pathname: location.pathname, search: encodeUri({ ...uri, start: i }) });
            }}
          />
          <div
            className={classNames(
              'grid grid-cols-4 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-5',
              ' justify-between  pt-2.5 pb-10  relative min-h-40 ',
            )}
          >
            {videoLoading ? (
              <div className="absolute top-1/3 right-1/2">
                <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
              </div>
            ) : (
              <>
                {data?.medicines.data
                  .sort((a, b) => a?.dci?.name.localeCompare(b?.dci?.name, 'en', { sensitivity: 'base' }))
                  .map((e) => (
                    <>
                      {e?.video?.seringue.length > 0 && (
                        <VideoCard
                          className="h-full"
                          from="home"
                          key={e.id}
                          deviceType={e.name}
                          title={e.name}
                          dci={e.dci.name}
                          footer={e.lab}
                          medicinePhoto={e.image}
                          medicineId={e.id}
                          videoData={
                            e?.video?.seringue
                              ? { url: `${e?.video?.seringue}23`, qrCode: e?.video?.QRseringue }
                              : { url: '', qrCode: '' }
                          }
                          typeVideoOpen
                          medidineDescription={{
                            classe: e.classe?.name,
                            dci: e.dci?.name,
                            device: 'e.device?.name',
                            dosage: 'e.dose?.name',
                          }}
                        />
                      )}
                      {e?.video?.stylo.length > 0 && (
                        <VideoCard
                          className="h-full"
                          from="home"
                          key={e.id}
                          deviceType={e.name}
                          title={e.name}
                          dci={e.dci.name}
                          footer={e.lab}
                          medicinePhoto={e.image}
                          medicineId={e.id}
                          videoData={
                            e?.video?.stylo
                              ? { url: `${e?.video?.stylo}23`, qrCode: e?.video?.QRstylo }
                              : { url: '', qrCode: '' }
                          }
                          medidineDescription={{
                            classe: e.classe?.name,
                            dci: e.dci?.name,
                            device: 'e.device?.name',
                            dosage: 'e.dose?.name',
                          }}
                        />
                      )}
                    </>
                  ))}
              </>
            )}
          </div>
        </div>
        <button
          className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
          onClick={() => scrollToTop()}
        >
          <img alt="up" src={Up} height={30} />
        </button>
      </div>
    </>
  );
};

export default PageVideos;
