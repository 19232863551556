import Up from 'assets/svg/goUp.svg';
import Card from 'components/common/Card/Card';
import Search from 'components/common/Search/Search';
import ZephirDescription from 'components/common/ZephirDescription';
import FilterAlpha from 'components/ux/FilterAlphabetical';
import cardIdContext from 'contexts/CardIdContext';
import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyMedicines } from 'requests/medicaments';
import classNames from 'utils/classNames';
import { Spinner } from 'utils/loading';
import { decodeUri, encodeUri } from 'utils/url';
import Splf from '../../components/Splf';
import classes from './HomeContainer.module.scss';
import classes2 from './HomeContainer2.module.scss';

export const PER_PAGE = 100;

const MEDICAMENT_MODIFIERS = ['page', 'order', 'deviceType', 'dci', 'device', 'classe', 'indication', 'start'];

interface Props {
  title: React.ReactNode;
  filterComponent: React.ReactNode;
  typeFilter?: string;
}

const HomeContainer: React.FC<Props> = ({ title }) => {
  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);
  const [responsive, setresponsive] = useState(window.innerWidth < 1000);
  const { setMenuStates } = useContext(MenuContext);
  const [readSectionActive, setreadSectionActive] = useState(false);
  const { cardId, setCardId } = useContext(cardIdContext);
  const [, setvideoModal] = useState(false);
  const [dataCall, { data, loading: MedicineLoading }] = useLazyMedicines({
    fetchPolicy: 'no-cache',
  });
  useEffect(
    () => {
      dataCall({
        variables: {
          ...uri,
          sort: 'name',
          order: uri?.order === '-1' ? -1 : 1,
        },
      });
    },
    MEDICAMENT_MODIFIERS.map((modifier) => uri[modifier]),
  );

  useEffect(() => {
    const el = document.getElementById(cardId || '');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, uri]);

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);
  const checkResponsive = () => {
    if (window.innerWidth < 1000) {
      setresponsive(true);
    } else {
      setMenuStates(MenuStatesValues.CLOSE);
      setresponsive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', checkResponsive);
    return () => {
      window.removeEventListener('resize', checkResponsive);
    };
  }, []);
  const scrollToTop = () => {
    setCardId('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Search />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div
        className={classNames(
          'bg-grey-200 px-28 py-12 lg:px-20  sm:px-5 sm:py-1 ',
          !responsive ? classes2.container : classes.container2,
        )}
      >
        <div className={classNames('text-grey-400 text-2xl font-bold mb-1', classes.title)}>{title}</div>

        <div className={classNames(classes.result)}>
          <div className="flex justify-between lg:flex-col mb-5">
            <div className="text-grey-400 sm:text-2xl font-bold text-32 2xl:text-26"> Médicaments</div>
            <div className="flex flex-col items-end justify-end lg:items-start ">
              <div className="text-sm 2xl:text-xs flex items-center pt-3 lg:pt-0 pr-0.5">
                <div className="text-green font-semibold">{data?.medicines.count}</div>
                <div className="text-blue-600  font-normal pl-1 ">
                  Résultat{data?.medicines?.count! > 1 ? 's' : ''} trouvé{data?.medicines?.count! > 1 ? 's' : ''}
                </div>
              </div>
            </div>
          </div>
          <FilterAlpha
            uri={uri}
            className={responsive ? 'mb-0 lg:bg-transparent border-none flex-0.9' : 'mb-6'}
            filterAllDatas={() => {
              const newUri = { ...uri };
              delete newUri.start;
              return history.replace({ pathname: location.pathname, search: encodeUri(newUri) });
            }}
            filterdDatas={(i) => {
              history.replace({ pathname: location.pathname, search: encodeUri({ ...uri, start: i }) });
            }}
          />
          <p className="sm:text-sm">*Classés par ordre alphabétiques de DCI</p>
          <div className="grid grid-cols-4 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-4 justify-between gap-y-5 pt-2.5 pb-10 relative min-h-40">
            {MedicineLoading ? (
              <div className="absolute top-1/3 right-1/2">
                <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
              </div>
            ) : (
              <>
                {false && <Splf />}
                {data?.medicines.data
                  .sort((a, b) => a?.dci?.name.localeCompare(b?.dci?.name, 'en', { sensitivity: 'base' }))
                  .map((e) => {
                    return (
                      <>
                        <Card
                          className="h-full"
                          from="home"
                          key={e.id}
                          deviceType={e.name}
                          title={e.name}
                          dci={e.dci.name}
                          footer={e.lab}
                          medicinePhoto={e.image}
                          medicineId={e.id}
                          videoData={e?.video}
                          typeVideoOpen={setvideoModal}
                          medidineDescription={{
                            classe: e.classe?.name,
                            dci: e.dci?.name,
                            device: 'e.device?.name',
                            dosage: 'e.dose?.name',
                          }}
                        />
                      </>
                    );
                  })}
              </>
            )}
          </div>
        </div>
        <button
          className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
          onClick={() => scrollToTop()}
        >
          <img alt="up" src={Up} height={30} />
        </button>
      </div>
    </>
  );
};

export default HomeContainer;
