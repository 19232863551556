import logoAlize from 'assets/svg/logoAlizeWhite.svg';
import AboutIcon from 'components/icons/AboutIcon';
import Choix from 'components/icons/Choix';
import Delete from 'components/icons/Delete';
import Favorite from 'components/icons/Favorite';
import MenuIcon from 'components/icons/MenuIcon';
import Note from 'components/icons/Note';
import Severe from 'components/icons/Severe';
import cardIdContext from 'contexts/CardIdContext';
// import Video from 'components/icons/Video';
import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';
import PreviousMenuContext from 'contexts/PreviousMenuState';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyClasses } from 'requests/classes';
import { useLazyDCIS } from 'requests/dcis';
import { useLazyMedicinesMenu } from 'requests/medicaments';
import classNames from 'utils/classNames';
import MobileMenuGeneric from './MedicineMenuSection/MobileMenuGeneric';

const ResponsiveMenu = () => {
  const { MenuStates, setMenuStates } = useContext(MenuContext);
  const { setPreviousStates, PreviousStates } = useContext(PreviousMenuContext);
  const [getClasses, { data: classess }] = useLazyClasses();
  const [getDcis, { data: dcis }] = useLazyDCIS();
  const [getMedecine, { data: medecine }] = useLazyMedicinesMenu({});
  const history = useHistory();
  const { setCardId } = useContext(cardIdContext);

  useEffect(() => {
    switch (MenuStates) {
      case MenuStatesValues.medicamentsMenu:
        getMedecine();
        break;
      case MenuStatesValues.classeMenu:
        getClasses();
        break;
      case MenuStatesValues.dciMenu:
        getDcis();
        break;

      default:
        break;
    }
  }, [MenuStates]);

  useEffect(() => {
    const html = document?.querySelector('html');
    if (html) {
      if (MenuStates !== 'CLOSE') html.style.overflow = 'hidden';
      else html.style.overflow = 'unset';
    }
    return () => {
      if (html) html.style.overflow = 'unset';
    };
  }, [MenuStates]);

  const MenuGeneral = [
    {
      id: 0,
      image: Delete,
      name: 'Médicaments et liste de recherche',
      MenuTrigger: () => setMenuStates(MenuStatesValues.mainMedicineMenu),
      pathname: ['/', '/classes', '/dci'],
    },
    { id: 5, name: 'Asthme sévère', pathname: ['/asthmeSevere', ''], image: Severe },
    { id: 5, name: 'Comment discuter le traitement en RCA ?', pathname: ['/indications', ''], image: Choix },
    // {
    //   id: 1,
    //   name: 'Vidéos éducatives',
    //   image: Video,

    //   pathname: ['/videos', ''],
    // },
    {
      id: 2,
      name: 'À propos',
      image: AboutIcon,
      MenuTrigger: () => setMenuStates(MenuStatesValues.aboutMenu),
      pathname: ['/auteurs', '/gammeZephir', '/informations'],
    },
    {
      id: 4,
      name: 'Mes notes',
      image: Note,
      pathname: ['/notes', ''],
    },
    {
      id: 5,
      name: 'Mes favoris',
      image: Favorite,
      pathname: ['/favoris', ''],
    },
  ];

  const MenuAbout = [
    {
      id: 0,
      name: 'Auteurs',
      pathname: ['/auteurs'],
    },
    {
      id: 1,
      name: 'Éditorial',
      pathname: ['/informations'],
    },
    // {
    //   id: 2,
    //   name: 'Gamme ZEPHIR',
    //   pathname: ['/gammeZephir'],
    // },
  ];
  const MenuMedicine = [
    {
      id: -1,
      name: 'Liste des médicaments',
      pathname: ['/'],
    },
    {
      id: 1,
      name: 'Médicaments',
      MenuTrigger: () => setMenuStates(MenuStatesValues.medicamentsMenu),
      sectionName: 'medicamentsMenu',
      pathname: ['/medicament'],
    },
    {
      id: 2,
      name: 'Classes thérapeutiques',
      MenuTrigger: () => setMenuStates(MenuStatesValues.classeMenu),
      sectionName: 'classeMenu',
      pathname: ['/classes'],
    },
    {
      id: 3,
      name: 'DCI',
      MenuTrigger: () => setMenuStates(MenuStatesValues.dciMenu),
      sectionName: 'dciMenu',
      pathname: ['/dci'],
    },
    {
      id: 4,
      name: 'Vidéos éducatives',
      pathname: ['/videos'],
    },
  ];

  return (
    <header
      className={classNames(
        'hidden md:flex sm:flex  lg:flex h-28 w-full  items-center justify-between px-28 py-12 lg:px-7 md:px-5 sm:px-5 relative  ',
      )}
    >
      <div>
        {/* eslint-disable-next-line */}
        <img
          src={logoAlize}
          alt=""
          className="pr-32 lg:pr-0 sm:h-12 h-16"
          onClick={() => {
            history.push('/');
            setCardId('');
            setPreviousStates(MenuStatesValues.mainMenu);
          }}
        />
        <div className="text-blue-650 text-xs sm:text-10 sm:w-4/5	">
          Guide des biothérapies dans l’asthme sévère chez l’adulte
        </div>
      </div>

      <MenuIcon
        fill="black"
        className="cursor-pointer"
        width="26"
        height="18"
        onClick={() =>
          setMenuStates(PreviousStates !== MenuStatesValues.CLOSE ? PreviousStates : MenuStatesValues.mainMenu)
        }
      />
      {/* Menu Modals */}

      <MobileMenuGeneric
        returnSection={MenuStatesValues.CLOSE}
        data={MenuGeneral}
        open={MenuStatesValues.mainMenu}
        pathName="/MenuGeneral"
        paramName="MenuGeneral"
      />

      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMenu}
        data={MenuMedicine}
        open={MenuStatesValues.mainMedicineMenu}
        pathName="/medicine"
        paramName="medicine"
      />

      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMenu}
        data={MenuAbout}
        open={MenuStatesValues.aboutMenu}
        pathName="/about"
        paramName="about"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={
          medecine?.medicines?.data ? [...medecine.medicines.data].sort((a, b) => a.name?.localeCompare(b.name)) : []
        }
        open={MenuStatesValues.medicamentsMenu}
        pathName="/medicament"
        paramName=""
        titleMenu="Médicaments"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={classess?.classes.data ? [...classess.classes.data].sort((a, b) => a.name?.localeCompare(b.name)) : []}
        open={MenuStatesValues.classeMenu}
        pathName="/classes"
        paramName="classe"
        titleMenu="Classes thérapeutiques"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={dcis?.dcis.data ? [...dcis.dcis.data].sort((a, b) => a.name?.localeCompare(b.name)) : []}
        open={MenuStatesValues.dciMenu}
        pathName="/dci"
        paramName="dci"
        titleMenu="DCI"
      />
    </header>
  );
};

export default ResponsiveMenu;
