import ZephirDescription from 'components/common/ZephirDescription';
import Header from 'containers/DetailsMedication/Components/HeaderRouter';
import MenuItem from 'containers/PageStatique/MenuItem';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SplfCard from '../../components/Splf';
import Auteur from './Components/Auteur';
import Interets from './Components/Interet';

interface PagesData {
  name: string;
}

interface StaticPagesInfo {
  [key: string]: PagesData;
}

const PageStatique: FC = () => {
  const INFOS: StaticPagesInfo = {
    Auteurs: { name: 'Auteurs' },
    Interet: { name: "Déclaration d'intérêts" },
  };
  const history = useHistory();
  const { search } = useLocation();

  const name = new URLSearchParams(search).get('section');
  const [displaySection, setdisplaySection] = useState('Auteurs');
  const [currentSection, setcurrentSection] = useState<string | null>('Auteurs');

  useEffect(() => {
    history.push(`/auteurs?section=${displaySection}`);
    setcurrentSection(name);
  }, [displaySection, search, currentSection]);

  return (
    <div className="bg-grey-200 container mb-auto">
      <Header pageTitle="À propos" subTitle="Auteurs" />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className="px-28 lg:px-8 sm:px-4 flex lg:flex-col xs:flex row pt-38 m-auto justify-center  ">
        <div className="flex-0.3  ">
          <div className=" menu-info py-7 pr-6 flex-0.3 ">
            {Object.keys(INFOS).map((key) => (
              <MenuItem
                medicamentName={INFOS[key]?.name}
                target={key}
                setdisplaySection={setdisplaySection}
                currentSection={currentSection}
              />
            ))}
            <div className="w-11/12 	lg:hidden min-w-96">{false && <SplfCard colorType />}</div>
          </div>
        </div>
        <div className=" p-0 flex-0.7  ">
          {' '}
          <div className="mt-6 ml-4 lg:ml-0 grid grid-cols-1 text-justify">
            <Auteur display={displaySection} />
            <Interets display={displaySection} />
          </div>{' '}
        </div>
      </div>
    </div>
  );
};

export default PageStatique;
