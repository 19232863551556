import Cancel from 'components/icons/Cancel';
import Search from 'components/icons/Search';
import classNames from 'utils/classNames';
import Input from 'components/ux/Input';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'utils/loading';
import { useGeneralSearch } from 'requests/medicaments';
import { encodeUri } from 'utils/url';
import DropDown from '../DropDown/DropDown';

interface SearchProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
  id?: string;
}
const SearchComponent: React.FC<SearchProps> = ({ className, id }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { data: resultSearch, loading } = useGeneralSearch({
    variables: { name: search },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  function renderTitle(title: string) {
    return <div className="pl-8 2xl:pl-4 py-2 text-base text-blue-600 font-semibold bg-grey-400">{title}</div>;
  }

  const divRef = useRef<HTMLDivElement>(null);
  function renderMedecine() {
    const elements = [
      resultSearch?.generalSearch?.medicines?.length && (
        <React.Fragment key={1}>
          {renderTitle('Médicaments')}
          <div className="bg-grey-200">
            {resultSearch?.generalSearch.medicines
              ?.slice()

              ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))
              ?.map((d) => (
                <Link key={d.id} to={`/medicament/${d.id}?from=home`}>
                  <div className="hover:text-blue-750 hover:bg-white  cursor-pointer pt-3 pb-3">
                    <div className="w-11/12 m-auto capitalize">
                      {d.name}
                      {false && <span className="text-grey-700">()</span>}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </React.Fragment>
      ),
      resultSearch?.generalSearch?.classes.length && (
        <React.Fragment key={2}>
          {renderTitle('Classes thérapeutiques')}
          <div className="bg-grey-200 ">
            {resultSearch?.generalSearch?.classes
              ?.slice()
              ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))
              .map((d) => (
                <Link key={d.id} to={{ pathname: '/classes', search: encodeUri({ classe: d.id }) }}>
                  <div className="hover:text-blue-750 hover:bg-white cursor-pointer pt-3 pb-3">
                    <div className="w-11/12 m-auto capitalize">{d.name}</div>
                  </div>
                </Link>
              ))}
          </div>
        </React.Fragment>
      ),
      resultSearch?.generalSearch?.dcis?.length && (
        <React.Fragment key={2}>
          {renderTitle('DCI')}
          <div className="bg-grey-200 ">
            {resultSearch?.generalSearch?.dcis
              ?.slice()
              ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))
              ?.map((d) => (
                <Link key={d.id} to={{ pathname: '/dci', search: encodeUri({ dci: d.id }) }}>
                  <div className="hover:text-blue-750  hover:bg-white cursor-pointer pt-3 pb-3">
                    <div className="w-11/12 m-auto capitalize">{d.name}</div>
                  </div>
                </Link>
              ))}
          </div>
        </React.Fragment>
      ),
    ].filter((e) => e);

    if (elements.length) return elements;
    return <div className="items-center pt-3 pb-3 text-grey-600 w-11/12 m-auto capitalize">Aucun résultat trouvé</div>;
  }
  return (
    <div id={id} className={classNames(' h-28 bg-green flex justify-center items-center gap-6', className)}>
      <div ref={divRef} className="w-5/12 md:w-10/12">
        <Input
          placeholder="Moteur de recherche"
          onChange={(e) => {
            setOpen(true);
            setSearch(e.target.value);
          }}
          handleError={false}
          iconRight={
            <Cancel
              width={24}
              onClick={() => {
                setSearch('');
                setOpen(false);
              }}
            />
          }
          iconLeft={<Search width={18} />}
          value={search}
        />
      </div>
      <DropDown
        full
        onClose={() => setOpen(false)}
        top={10}
        className="rounded-5 max-h-96 overflow-auto"
        anchorEl={divRef}
        open={open}
      >
        {loading ? <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" /> : renderMedecine()}
      </DropDown>
    </div>
  );
};
export default SearchComponent;
