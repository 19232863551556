import FilterByIndications from 'components/filtres/FilterByIndications';
import { useHistory, useLocation } from 'react-router-dom';
import { useDCI, useDCIS } from 'requests/dcis';
import { decodeUri, encodeUri } from 'utils/url';
import HomeGeneric from '../HomeGeneric/HomeGeneric';

const DCI = () => {
  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);
  const { data: dataDCI } = useDCI({ variables: { id: uri.dci }, fetchPolicy: 'no-cache' });
  const { data: dataDCIs } = useDCIS({ fetchPolicy: 'no-cache' });
  function handleChoosed(value: any) {
    if (value) {
      delete uri.start;
      history.replace({
        pathname: location.pathname,
        search: encodeUri({ ...uri, dci: value[value.length - 1] }),
      });
    }
  }
  const textSelected = dataDCI?.dci.name;
  return (
    <HomeGeneric
      from="dci"
      title="DCI"
      textSelected={textSelected?.replace(/^./, textSelected[0].toUpperCase())}
      filterComponent={
        <>
          <FilterByIndications data={dataDCIs?.dcis?.data} indications={uri.dci} handleChoosed={handleChoosed} />
        </>
      }
    />
  );
};

export default DCI;
