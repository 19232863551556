import FilterByIndications from 'components/filtres/FilterByIndications';
import { useHistory, useLocation } from 'react-router-dom';
import { useClasse, useClasses } from 'requests/classes';
import { decodeUri, encodeUri } from 'utils/url';
import HomeGeneric from '../HomeGeneric/HomeGeneric';

const Classes = () => {
  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);

  const { data: dataClasse } = useClasse({ variables: { id: uri.classe }, fetchPolicy: 'no-cache' });
  const { data: dataClasses } = useClasses({ fetchPolicy: 'no-cache' });
  function handleChoosed(value: any) {
    if (value) {
      delete uri.start;
      history.replace({
        pathname: location.pathname,
        search: encodeUri({ ...uri, classe: value[value.length - 1] }),
      });
    }
  }

  const textSelected = dataClasse?.classe.name;
  return (
    <HomeGeneric
      from="classe"
      title="Classes thérapeutiques"
      textSelected={textSelected?.replace(/^./, textSelected[0].toUpperCase())}
      filterComponent={
        <>
          <FilterByIndications
            data={dataClasses?.classes?.data}
            indications={uri.classe}
            handleChoosed={handleChoosed}
          />
        </>
      }
    />
  );
};

export default Classes;
