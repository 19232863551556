/* eslint-disable no-nested-ternary */
import ImagePreview from 'components/common/PreviewImage/ImagePreview';
import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'utils/classNames';

export type MedicationProps = {
  name?: string | undefined;
  classe?: { id: string; name: string } | undefined;
  classeFull?: string | undefined;
  dci?: { id: string; name: string } | undefined;
  lab?: string | undefined;
  dataMoleculeImage?: string;
  presentation?: { titleText: string; list: string[] } | undefined;
  actionModality?: string | undefined;
  actionModalityV2?: string[] | undefined;
  Indications?:
    | {
        from: string;
        mainIndication: string;
        subIndication: { value: string; name: string; list: string[] }[];
      }[]
    | undefined;
  doseAdaption?: { title: string; list: string[] }[] | undefined;
  AdministrationMode?: string | undefined;
  frequencyInjection?:
    | {
        name: string;
        notice: string;
      }
    | undefined;
  predictifFactors?:
    | {
        header: string;
        sections: string[];
        notice: string;
      }
    | undefined;
  sideEffects?: string | undefined;
  EffectivenessEvaluation?: string[] | undefined;
  treatmentDuration?: string | undefined;
  PregnancyBreastFeeding?:
    | {
        notice: string;
        pregnancy: string;
        breastFeeding: string;
      }
    | undefined;
  Price?:
    | {
        mainPrice: string;
        price: string[];
      }
    | undefined;
  refund?:
    | {
        value: string;
        notices: string[];
      }
    | undefined;
  opinion?: string | undefined;
  rcp?: string | undefined;
  sameClasses?: string | undefined;
  classesAndIndications?: string | undefined;
  otherClassesAndIndications?: string | undefined;
  video?:
    | {
        url: string;
        qrCode: string;
      }
    | undefined;
  image?: string | undefined;
  moleculeImage?: string | undefined;

  tableImage?: string | undefined;
  col?: boolean;
};

const MedicationTable: FunctionComponent<MedicationProps> = ({
  name,
  classe,
  classeFull,
  dci,
  lab,
  presentation,
  actionModality,
  actionModalityV2,
  Indications = [],
  doseAdaption,
  AdministrationMode,
  frequencyInjection,
  predictifFactors,
  sideEffects,
  EffectivenessEvaluation,
  treatmentDuration,
  PregnancyBreastFeeding,
  Price,
  refund,
  sameClasses,
  classesAndIndications,
  otherClassesAndIndications,
  video,
  image,
  col,
  tableImage,
  moleculeImage,
  dataMoleculeImage,
  ...rest
}) => {
  const TopColum = [
    {
      title: 'Classe pharmacothérapeutique :',
      des: `${classeFull} (${classe?.name})`,
    },
    {
      title: 'DCI :',
      des: `${dci?.name}`[0].toUpperCase() + `${dci?.name}`.substring(1),
    },
    {
      title: ' Laboratoire :',
      des: lab,
    },
    {
      title: ' Présentations :',
      des: presentation?.titleText,
      list: presentation?.list,
    },
    {
      title: "Modalités d'action  :",
      des: name !== 'TEZSPIRE®' && actionModality ? actionModality : '',
      list: name === 'TEZSPIRE®' && actionModalityV2 ? actionModalityV2 : [],
    },
  ];
  const columnA = [
    {
      title: 'Fréquence des injections  :',
      des: frequencyInjection?.name,
      list: frequencyInjection?.notice.length ? [frequencyInjection?.notice] : undefined,
    },
    {
      title: "Mode d'administration  :",
      des: AdministrationMode,
    },
  ].filter((e) => e.des?.length || e.list?.length);
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div
      className={classNames(
        'flex flex-col relative justify-center items-start w-full border lg:border-b-0	rounded-5 border-blue-250 p-4 sm:p-1',
      )}
      {...rest}
    >
      {false && (
        <img
          src={moleculeImage}
          alt=""
          className="rounded-5 w-100 p-4 sm:p-0 absolute md:relative top-0 right-3 w-351 "
        />
      )}
      <div className={classNames('flex flex-row lg:flex-col justify-start items-start w-full ')}>
        {true && (
          <div className="flex flex-col gap-1 justify-center items-start w-full  	">
            {TopColum.map((i) => (
              <div className="text-sm 2xl:text-sm whitespace-nowrap 2xl:leading-6 3xl:leading-6 w-full">
                <span className="font-bold text-blue-600 ">{i?.title} </span>
                <span className="font-sans text-blue-600 whitespace-normal"> {i?.des} </span>
                {!!i.list &&
                  i?.list.map((e) => (
                    <span className="font-sans text-blue-600 whitespace-normal">
                      <br />- {e && e?.trim().charAt(0).toUpperCase() + e.substring(1)}
                    </span>
                  ))}

                <br />
              </div>
            ))}
            {width < 1000 && <ImagePreview src={dataMoleculeImage} alt="fdfs" className="rounded-5 w-100 m-auto m-1" />}

            {name === 'FASENRA®' && width < 1000 && (
              <div className="text-xs lg:m-auto">
                ADCC = antibody-dependent cellular cytotoxicity IL 5 = interleukine 5 NK = natural killer
              </div>
            )}
            <span className="font-bold text-sm mt-2 text-blue-600 ">
              Indications : {Indications[0]?.from?.length ? `(${Indications[0]?.from})` : ''}{' '}
            </span>
            {Indications?.map(({ mainIndication, subIndication }, index, array) => (
              <div className=" md:pl-0 pl-6">
                <span className=" font-semibold  text-sm text-blue-600 ">
                  {mainIndication?.trim()[0] !== '>' && array?.length >= 2 ? `${index + 1})` : null}{' '}
                  {mainIndication?.trim()[0] === '>'
                    ? mainIndication?.trim()[0]?.toUpperCase()?.slice(1) + mainIndication?.trim()?.substring(1)
                    : mainIndication[0]?.toUpperCase() + mainIndication?.trim()?.substring(1)}
                </span>

                <ul className="">
                  {subIndication?.length
                    ? subIndication?.map(({ value = '', name: listTitle, list }) => (
                        <li>
                          {value?.trim()[0] === '>' ? (
                            value?.length ? (
                              <span className="font-sans text-sm text-blue-600 whitespace-normal pl-2">
                                <p className="p-0" />
                                {value?.trim()[0]?.toUpperCase()?.slice(1) + value?.trim()?.substring(1)}
                                <br />
                              </span>
                            ) : null
                          ) : list?.length ? (
                            <div>
                              <span className="flex uppercase">{listTitle}:</span>
                              <ul className="list-disc -mb-3	">
                                {list?.map(
                                  (e) =>
                                    e?.length > 3 && (
                                      <li className="font-sans text-sm text-blue-600 whitespace-normal ml-7">
                                        {e?.trim()[0]?.toUpperCase() + e?.trim()?.substring(1)}
                                        <br />
                                      </li>
                                    ),
                                )}
                              </ul>
                            </div>
                          ) : value?.length ? (
                            <span className="font-sans text-sm text-blue-600 no-underline whitespace-normal list-none">
                              - {value?.trim()[0].toUpperCase() + value?.trim()?.substring(1)}
                              <br />
                            </span>
                          ) : null}
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            ))}
            {doseAdaption?.length ? (
              <div>
                <span className="font-bold text-sm mt-2 text-blue-600 ">Posologie - Adaptation des doses :</span>
                <ul className="list-disc ">
                  {doseAdaption?.map(({ title, list }) => (
                    <div className=" md:pl-0 pl-6">
                      {title?.length ? (
                        <li className=" text-sm text-blue-600 ml-6 ">
                          {title ? title[0].toUpperCase() + title.trim()?.substring(1) : ''}{' '}
                        </li>
                      ) : null}

                      <ul className="">
                        {list?.length
                          ? list?.map((ee) => (
                              <li>
                                {list?.length ? (
                                  <div>
                                    <ul className=" -mb-0	">
                                      <li className="font-sans text-sm -mb-5 text-blue-600 whitespace-normal my-2 ">
                                        - {ee?.trim()[0]?.toUpperCase() + ee?.trim()?.substring(1)}
                                      </li>
                                    </ul>
                                  </div>
                                ) : null}
                                <br />
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  ))}
                </ul>
              </div>
            ) : null}

            {columnA.map((i) => (
              <div className="text-sm 2xl:text-sm whitespace-nowrap 2xl:leading-6 3xl:leading-6">
                <span className="font-bold text-blue-600 ">{i.title} </span>
                <span className="font-sans text-blue-600 whitespace-normal"> {i.des} </span>
                {i?.list?.length &&
                  i?.list.map((e) => (
                    <span className="font-sans text-blue-600 whitespace-normal pl-2">
                      {' '}
                      <br /> - {e && e?.trim()[0]?.toUpperCase() + e?.substring(1)}
                    </span>
                  ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default MedicationTable;
