import Up from 'assets/svg/goUp.svg';
import Card from 'components/common/Card/Card';
import ZephirDescription from 'components/common/ZephirDescription';
import Filter from 'components/icons/Filter';
import FilterIcon from 'components/icons/FilterIcon';
import ReturnIcon2 from 'components/icons/ReturnIcon2';
import MobileMenu from 'components/layout/Header/MobileMenu';
import Splf from 'components/Splf';
import FilterAlpha from 'components/ux/FilterAlphabetical';
import HeaderRouterHeader from 'containers/DetailsMedication/Components/HeaderRouter';
import cardIdContext from 'contexts/CardIdContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyMedicines } from 'requests/medicaments';
import classNames from 'utils/classNames';
import { Spinner } from 'utils/loading';
import { decodeUri, encodeUri } from 'utils/url';
import classes2 from './HomeGeneric.module.scss';
import classes from './HomeGeneric2.module.scss';
import ResponsiveFilter from './ResponsiveFilter';

export const PER_PAGE = 100;

const MEDICAMENT_MODIFIERS = [
  'page',
  'order',
  'deviceType',
  'dci',
  'device',
  'classe',
  'indication',
  'start',
  'lab',
  'generic',
];

interface Props {
  title: React.ReactNode;
  filterComponent: React.ReactNode;
  typeFilter?: string;
  from?: string;
  generic?: boolean;
  textSelected?: string;
}

const HomeContainer: React.FC<Props> = ({ title, filterComponent, typeFilter, from, textSelected }) => {
  const location = useLocation();
  const history = useHistory();
  const [readSectionActive, setreadSectionActive] = useState(false);
  const { cardId, setCardId } = useContext(cardIdContext);
  let titleFilter = '';
  if (React.isValidElement(title)) {
    titleFilter = title?.props?.children[0]?.props?.description;
  }

  const uri = decodeUri(location.search);
  const [responsive, setresponsive] = useState(window.innerWidth < 1000);
  const [filterMenuState, setfilterMenuState] = useState(false);
  const [, setvideoModal] = useState(false);
  const [dataCall, { data, loading: MedicineLoading }] = useLazyMedicines({
    fetchPolicy: 'no-cache',
  });

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  const scrollToTop = () => {
    setCardId('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  useEffect(
    () => {
      dataCall({
        variables: {
          ...uri,
          sort: 'name',
          order: uri?.order === '-1' ? -1 : 1,
        },
      });
    },
    MEDICAMENT_MODIFIERS.map((modifier) => uri[modifier]),
  );

  useEffect(() => {
    const el = document.getElementById(cardId || '');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, uri]);

  const checkResponsive = () => setresponsive(window.innerWidth < 1000);
  useEffect(() => {
    window.addEventListener('resize', checkResponsive);
    return () => {
      window.removeEventListener('resize', checkResponsive);
    };
  }, []);

  return (
    <>
      <HeaderRouterHeader
        onClick={() => {
          history.push('/');
        }}
        className=" py-6"
        pageTitle="Médicaments"
        subTitle={`${title}`}
        subsubTitle={textSelected}
        previousPage="/"
      />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div
        className={classNames(
          'bg-grey-300 px-28 py-12 lg:px-8 sm:px-5 relative',
          responsive ? classes2.container2 : classes.container,
        )}
      >
        <div className={classNames('text-grey-400 text-32 2xl:text-26 lg:text-22  font-bold', classes.title)}>
          {textSelected || title}
        </div>

        {!responsive && (
          <>
            {' '}
            <div className={classNames(classes.filter)}>
              <div className="flex rounded-5 childd bg-white border border-blue-150">
                <div className="flex-auto  px-10 py-7">
                  <div className="flex mb-7  items-center ">
                    <Filter height={17} className="pr-6 2xl:pr-5" />

                    <div className="font-bold text-2xl 2xl:text-xl">Rechercher par</div>
                  </div>

                  {(titleFilter === 'Laboratoires' || titleFilter === 'Indications') && (
                    <>
                      <div className="text-blue-600 font-semibold">{titleFilter} </div>
                      <div className="h-1 w-1/2 bg-purple-100 my-2">
                        <div className="relative h-full bg-blue w-1/3 left-3.5" />
                      </div>
                    </>
                  )}
                  <div className="flex text-sm font-medium flex-col pb-14">
                    {typeFilter && (
                      <div className="block text-base 2xl:text-sm font-medium pb-4 text-grey-500">{typeFilter}</div>
                    )}

                    {filterComponent}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className={classNames(
            'text-sm 2xl:text-xs flex items-center self-center  pr-0.5 sm:pb-3 justify-end lg:justify-start	md:pt-0',
            classes.count,
          )}
        >
          <div className="text-yellow font-semibold">{data?.medicines.count}</div>
          <div className="text-blue-600  font-normal pl-1 ">
            Résultat{data?.medicines?.count! > 1 ? 's' : ''} trouvé{data?.medicines?.count! > 1 ? 's' : ''}
          </div>
        </div>
        <div className={classNames(classes.result)}>
          <div className="flex items-center justify-center">
            {responsive && (
              <div onClick={() => setfilterMenuState(true)} className="flex justify-start items-center  flex-0.1">
                <div className="bg-white border border-blue-150 p-3 border-5">
                  <FilterIcon height="12" />
                </div>
              </div>
            )}
            <FilterAlpha
              filterAllDatas={() => {
                const newUri = { ...uri };
                delete newUri.start;
                return history.replace({ pathname: location.pathname, search: encodeUri(newUri) });
              }}
              uri={uri}
              className={responsive ? 'mb-0 lg:bg-transparent border-none flex-0.9' : 'mb-6'}
              filterdDatas={(i) => {
                history.replace({ pathname: location.pathname, search: encodeUri({ ...uri, start: i }) });
              }}
            />
          </div>
          <div className="grid grid-cols-4 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-4 justify-between gap-y-5 pt-2.5 pb-10 relative min-h-40">
            {MedicineLoading ? (
              <div className="absolute top-1/3 right-1/2">
                <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
              </div>
            ) : (
              <>
                {false && <Splf />}
                {data?.medicines.data
                  .sort((a, b) => a?.dci?.name.localeCompare(b?.dci?.name, 'en', { sensitivity: 'base' }))
                  .map((e) => {
                    return (
                      <Card
                        dci={e?.dci?.name}
                        className="h-full"
                        from={from}
                        key={e.id}
                        deviceType={e?.name}
                        title={e?.name}
                        footer={e?.lab}
                        medicinePhoto={e?.image}
                        medicineId={e?.id}
                        videoData={e.video}
                        typeVideoOpen={setvideoModal}
                        medidineDescription={{
                          classe: e?.classe?.name,
                          dci: e?.dci?.name,
                          device: e?.name,
                          dosage: e?.name,
                        }}
                      />
                    );
                  })}
              </>
            )}
          </div>
        </div>
        <MobileMenu type="filter" responsiveMenuState={filterMenuState && responsive}>
          <ReturnIcon2
            className=" absolute left-12 top-12 cursor-pointer h-5 md:h-4 md:left-10"
            fill="#014A8D"
            onClick={() => setfilterMenuState(false)}
          />

          <ResponsiveFilter
            responsive={responsive}
            titleFilter={typeof title === 'string' ? title : ''}
            typeFilter={typeFilter}
            filterComponent={filterComponent}
          />
        </MobileMenu>
        <button
          className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
          onClick={() => scrollToTop()}
        >
          <img alt="up" src={Up} height={30} />
        </button>
      </div>
    </>
  );
};

export default HomeContainer;
