import EmptyNote from 'assets/svg/EmptyNote.svg';
import Up from 'assets/svg/goUp.svg';
import Button from 'components/Button';
import Note from 'components/common/NoteCard/NoteCard';
import ZephirDescription from 'components/common/ZephirDescription';
import Plus from 'components/icons/Plus';
import NoteModal from 'components/ux/ModalDetailNote';
import Pagination from 'components/ux/Pagination';
import Header from 'containers/DetailsMedication/Components/HeaderRouter';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useNotes } from 'requests/note';
import classNames from 'utils/classNames';
import { Spinner } from 'utils/loading';
import { decodeUri, encodeUri } from 'utils/url';
import { PER_PAGE } from '../HomeGeneric/HomeGeneric';
import DeleteNoteModal from './DeleteNote';
// import ZephirDescription from 'components/common/ZephirDescription';
import classes from './note.module.scss';

const Notes = () => {
  const history = useHistory();
  const location = useLocation();
  const uri = decodeUri(location.search);
  const [modalOpen, setModalOpen] = useState(-2);
  const [readSectionActive, setreadSectionActive] = useState(false);

  const {
    data,
    loading: noteLoading,
    called,
    refetch,
  } = useNotes({
    variables: { perPage: PER_PAGE, page: uri.page ? Number(uri.page) : 1 },
    fetchPolicy: 'network-only',
  });

  const [remove, setRemove] = useState(-1);

  const {
    page: currentPage = 1,
    totalPages,
    data: notes,
  } = useMemo(
    () =>
      data?.notes
        ? {
            data: data.notes.data,
            page: data.notes.page,
            totalPages: data.notes.totalPages,
          }
        : { data: [] },
    [data?.notes],
  );

  function onPageChange(nextPage: number) {
    history.replace({ pathname: location.pathname, search: encodeUri({ ...uri, page: nextPage }) });
    window.scroll({ top: 0 });
  }

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  if (noteLoading) {
    return (
      <div>
        <Header pageTitle="Mon espace" subTitle="Mes Notes" />

        <div className="grid grid-cols-4 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-4 justify-between gap-y-5 pt-2.5 pb-10 relative min-h-40">
          <div className="absolute top-1/3 right-1/2">
            <Spinner name="line-scale-pulse-out-rapid" color="#00a1a2" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header pageTitle="Mon espace" subTitle="Mes Notes" />
      <ZephirDescription width="65px" className="justify-center h-14" />
      {notes?.length && called ? (
        <div className="bg-grey-200 px-28 py-12 lg:px-7 sm:px-5  pt-8 ">
          <div className="flex pb-10 items-center justify-between ">
            <div className="font-semibold text-32 2xl:text-26 lg:text-22 "> Mes Notes</div>
            <Button
              onClick={() => setModalOpen(-1)}
              className={classNames(' cursor-pointer w-32 hover:bg-green hover:text-white ', classes.btnContainer)}
              variant="secondary"
            >
              <Plus height={14} className="pr-1.5" />
              <div className={`${classes.btnContent} `}>Ajouter</div>
            </Button>
          </div>
          <div
            className={classNames(
              'grid grid-cols-3 2xl:grid-cols-2 sm:grid-cols-1 lg:grid-cols-1  sm:grid-cols-1',
              'gap-4 justify-between gap-y-5 pt-2.5 pb-10',
            )}
          >
            {notes?.map((e, index) => (
              <>
                <Note
                  onEdit={() => setModalOpen(index)}
                  onDelete={() => setRemove(index)}
                  content={e.content}
                  name={e.medicine?.name}
                  title={e.title}
                  noteId={e.id}
                  medId={e.medicine?.id}
                  imgSrc={e.medicine?.image}
                  dateC={e.createdAt}
                />
              </>
            ))}
          </div>
          {totalPages ? (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          ) : null}
        </div>
      ) : (
        <div className="flex flex-col items-center p-2 bg-grey-200 min-h-100 px-28 lg:px-12">
          <div className="image w-2/5 lg:w-full">
            <img alt="emptyFav" src={EmptyNote} className="m-auto" />
          </div>
          <div className="textSection mb-12 items-center flex flex-col">
            <div className="text-26  font-bold lg:text-base 2xl:text-22 text-center">Aucune note</div>
            <div className="text-grey-250 p-4 text-22 lg:text-18 font-bold  lg:pt-0 pt-0">
              <Button
                onClick={() => setModalOpen(-1)}
                className="font-semibold  cursor-pointer text-blue-750 sm:text-sm lg:text-22"
              >
                Ajouter une note
              </Button>
            </div>
          </div>
        </div>
      )}
      <NoteModal
        note={notes[modalOpen]}
        open={modalOpen > -2}
        onClose={(updated) => {
          setModalOpen(-2);
          if (updated) {
            refetch();
          }
        }}
        remove={remove}
      />
      <DeleteNoteModal
        id={notes[remove]?.id}
        onDelete={(updated) => {
          setRemove(-1);
          if (updated) {
            refetch();
          }
        }}
      />
      <button className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`} onClick={() => scrollToTop()}>
        <img alt="up" src={Up} height={30} />
      </button>
    </div>
  );
};

export default Notes;
