import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { useLocalLazyQuery, useLocalMutation, useLocalQuery } from 'hooks/apollo';

export const medicamentsQuery = gql`
  query medicines($perPage: Int, $name: String) {
    medicines(perPage: $perPage, name: $name, sort: "name") {
      data {
        id
        name
      }
    }
  }
`;
export interface MedicamentsArguments {
  perPage?: number;
  name?: string;
  medicine?: string;
}
export interface MedicamentsData {
  data: {
    id: string;
    name: string;
  }[];
}
export interface GeneralResultSearch {
  medicines: Medicine[];
  classes: { id: string; name: string }[];
  dcis: { id: string; name: string }[];
}
const GeneralSearchQuery = gql`
  query generalSearch($name: String) {
    generalSearch(name: $name) {
      medicines {
        id
        name
      }
      classes {
        id
        name
      }

      dcis {
        id
        name
      }
    }
  }
`;
export const useLazyMedicaments = (
  options: QueryHookOptions<{ medicines: MedicamentsData }, MedicamentsArguments> = {},
) => useLocalLazyQuery(medicamentsQuery, options);
export const useMedicaments = (options: QueryHookOptions<{ medicines: MedicamentsData }, MedicamentsArguments> = {}) =>
  useLocalQuery(medicamentsQuery, options);
export const useGeneralSearch = (
  options: QueryHookOptions<{ generalSearch: GeneralResultSearch }, MedicamentsArguments> = {},
) => useLocalQuery(GeneralSearchQuery, options);
export const useLazyGeneralSearch = (
  options: QueryHookOptions<{ generalSearch: GeneralResultSearch }, MedicamentsArguments> = {},
) => useLocalLazyQuery(GeneralSearchQuery, options);
export const medicineQuery = gql`
  query medicines($perPage: Int, $sort: String, $page: Int, $order: Int, $dci: ID, $start: String, $classe: ID) {
    medicines(perPage: $perPage, sort: $sort, page: $page, order: $order, dci: $dci, start: $start, classe: $classe) {
      count
      page
      data {
        id
        name
        classe {
          id
          name
        }
        classeFull
        dci {
          id
          name
        }
        lab
        presentation {
          titleText
          list
        }
        actionModality
        actionModalityV2
        Indications {
          mainIndication
          subIndication {
            name
            value
            list
          }
        }
        doseAdaption {
          title
          list
        }
        AdministrationMode
        frequencyInjection {
          name
          notice
        }
        predictifFactors {
          header
          sections
          notice
        }
        sideEffects
        EffectivenessEvaluation
        treatmentDuration
        PregnancyBreastFeeding {
          notice
          pregnancy
          breastFeeding
        }
        Price {
          mainPrice
          price
        }
        refund {
          value
          notices
        }
        opinion
        rcp
        sameClasses
        classesAndIndications
        otherClassesAndIndications
        otherClasseIndicationComparable
        video {
          stylo
          QRstylo
          QRseringue
          seringue
          url
          qrCode
        }
        image
      }
      totalPages
    }
  }
`;
export const medicineQueryMenu = gql`
  query medicines($perPage: Int, $sort: String, $page: Int, $order: Int, $dci: ID, $start: String, $classe: ID) {
    medicines(perPage: $perPage, sort: $sort, page: $page, order: $order, dci: $dci, start: $start, classe: $classe) {
      count
      page
      data {
        id
        name
      }
      totalPages
    }
  }
`;
const suggestedQuery = gql`
  query getSuggestion($_id: String!) {
    getSuggestion(_id: $_id) {
      classdiffdispositifdiff {
        id
        name
        dci {
          id
          name
        }
        classe {
          id
          name
        }
      }
      classdiffdispositifmeme {
        id
        name
        dci {
          id
          name
        }
        classe {
          id
          name
        }
      }
      dispositifdifferentt {
        id
        name
        dci {
          id
          name
        }
        classe {
          id
          name
        }
      }
      dispositifmemee {
        id
        name
        dci {
          id
          name
        }
        classe {
          id
          name
        }
      }
    }
  }
`;
export interface MedicinesArguments {
  perPage?: number;
  sort?: string;
  page?: number;
  order?: number;
  dci?: string;
  start?: string;
  classe?: string;
}
export interface Medicine {
  id: string;
  name: string;
  image: string;
  lab: string;
  video: {
    QRstylo: string;
    stylo: string;
    QRseringue: string;
    seringue: string;
    url: string;
    qrCode: string;
  };
  dci: {
    id: string;
    name: string;
  };
  classe: {
    id: string;
    name: string;
  };
}
export interface Medicines {
  data: Medicine[];
  count: number;
  page: number;
  totalPages: number;
}

export interface SuggestionsList {
  classdiffdispositifdiff: Medicine[];
  classdiffdispositifmeme: Medicine[];
  dispositifdifferentt: Medicine[];
  dispositifmemee: Medicine[];
}
export const useMedicine = (options: QueryHookOptions<{ medicines: Medicines }, MedicinesArguments> = {}) =>
  useLocalQuery(medicineQuery, options);
export const useLazyMedicines = (options: QueryHookOptions<{ medicines: Medicines }, MedicinesArguments> = {}) =>
  useLocalLazyQuery(medicineQuery, options);
export const useLazyMedicinesMenu = (options: QueryHookOptions<{ medicines: Medicines }, MedicinesArguments> = {}) =>
  useLocalLazyQuery(medicineQueryMenu, options);
export const useSuggestedMedicine = (
  options: QueryHookOptions<{ getSuggestion: SuggestionsList }, { _id: string }> = {},
) => useLocalQuery(suggestedQuery, options);

export const MedicamentsCountQuery = gql`
  query medicines($dci: ID, $classe: ID) {
    medicines(dci: $dci, classe: $classe) {
      count
      page
      data {
        id
        name
        image
        video {
          url
          qrCode
        }
        qr {
          seringue
          stylo
        }
        classe {
          id
          name
        }
        dci {
          id
          name
        }
      }
      totalPages
    }
  }
`;
export const addFavouriteQuery = gql`
  mutation CreateFavorites($medicine: ID!) {
    createFavorites(medicine: $medicine) {
      id
      user {
        id
      }
      medicine {
        id
        name
      }
    }
  }
`;
export interface AddFavouriteResponseData {
  id: string;
  user: {
    id: string;
  };
  medicine: {
    id: string;
    name: string;
  };
}

export const useMedicamentsCount = (
  options: QueryHookOptions<{ medicines: { count: number; data: Medicine[] } }, {}> = {},
) => useLocalQuery(medicineQuery, options);

export const useAddFavourite = (
  options: MutationHookOptions<{ createFavorites: AddFavouriteResponseData }, { medicine: string }> = {},
) => useLocalMutation(addFavouriteQuery, options);
export const MedicamentsByIdQuery = gql`
  query medicine($id: ID!) {
    medicine(id: $id) {
      id
      name
      moleculeImage
      tableImage
      classe {
        id
        name
      }
      classeFull
      dci {
        id
        name
      }
      lab
      presentation {
        titleText
        list
      }
      actionModality
      actionModalityV2
      Indications {
        from
        mainIndication
        subIndication {
          name
          value
          list
        }
      }
      doseAdaption {
        title
        list
      }
      AdministrationMode
      frequencyInjection {
        name
        notice
      }
      predictifFactors {
        header
        sections
        notice
      }
      sideEffects
      EffectivenessEvaluation
      treatmentDuration
      PregnancyBreastFeeding {
        notice
        pregnancy
        breastFeeding
      }
      Price {
        mainPrice
        price
      }
      refund {
        value
        notices
      }
      opinion
      rcp
      sameClasses
      classesAndIndications
      otherClassesAndIndications
      otherClasseIndicationComparable
      video {
        url
        qrCode
        stylo
        seringue
        QRstylo
        QRseringue
      }
      qr {
        stylo
        seringue
      }
      image
      updatedAt
    }
  }
`;
export interface MedicamentsByIdData {
  medicine: {
    id: string;
    name: string;
    moleculeImage: string;
    tableImage: string;
    classe: {
      id: string;
      name: string;
    };
    classeFull: string;
    dci: {
      id: string;
      name: string;
    };
    lab: string;
    presentation: {
      titleText: string;
      list: string[];
    };
    actionModality: string;
    actionModalityV2?: string[];
    Indications: {
      from: string;
      mainIndication: string;
      subIndication: { name: string; value: string; list: string[] }[];
    }[];
    doseAdaption: { title: string; list: string[] }[];
    AdministrationMode: string;
    frequencyInjection: {
      name: string;
      notice: string;
    };
    predictifFactors: {
      header: string;
      sections: string[];
      notice: string;
    };
    sideEffects: string;
    EffectivenessEvaluation: string[];
    treatmentDuration: string[];
    PregnancyBreastFeeding: {
      notice: string;
      pregnancy: string;
      breastFeeding: string;
    };
    Price: {
      mainPrice: string;
      price: string[];
    };
    refund: {
      value: string;
      notices: string[];
    };
    opinion: string;
    rcp: string;
    sameClasses: string;
    classesAndIndications: string;
    otherClassesAndIndications: string;
    otherClasseIndicationComparable: string;
    video: {
      url: string;
      qrCode: string;
      QRseringue: string;
      QRstylo: string;
      stylo: string;
      seringue: string;
    };
    qr: {
      stylo: string;
      seringue: string;
    };
    image: string;
    updatedAt: string;
  };
}
export const useMedicamentsById = (options: QueryHookOptions<MedicamentsByIdData> = {}) =>
  useLocalQuery(MedicamentsByIdQuery, options);
export const medicineLettersQuery = gql`
  query MedicineLetters($dci: ID, $classe: ID) {
    medicineLetters(dci: $dci, classe: $classe)
  }
`;
export const useMedicineLetters = (options: QueryHookOptions<{ medicineLetters: string[] }> = {}) =>
  useLocalQuery(medicineLettersQuery, options);
export const relatedMedicineQuery = gql`
  query RelatedMedicine($medicine: ID!, $sort: String, $order: Int, $dci: ID, $start: String, $classe: ID) {
    relatedMedicine(medicine: $medicine, sort: $sort, order: $order, dci: $dci, start: $start, classe: $classe) {
      previous
      next
    }
  }
`;
interface RelatedMedicineArguments {
  medicine: string;
  sort?: string;
  order?: number;
  dci?: string;
  start?: string;
  classe?: string;
}
interface RelatedMedicineResponse {
  relatedMedicine: {
    previous?: string;
    next?: string;
  };
}
export const useRelatedMedicine = (options: QueryHookOptions<RelatedMedicineResponse, RelatedMedicineArguments> = {}) =>
  useLocalQuery(relatedMedicineQuery, options);
