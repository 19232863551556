import gql from 'graphql-tag';

import { QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery } from 'hooks/apollo';

export const classesQuery = gql`
  query classes($name: String) {
    classes(name: $name) {
      count
      data {
        id
        name
      }
    }
  }
`;

export interface ClassesData {
  count: number;
  data: {
    id: string;
    name: string;
  }[];
}

export const useClasses = (options: QueryHookOptions<{ classes: ClassesData }, {}> = {}) =>
  useLocalQuery(classesQuery, options);
export const useLazyClasses = (options: QueryHookOptions<{ classes: ClassesData }, {}> = {}) =>
  useLocalLazyQuery(classesQuery, options);

export const classeQuery = gql`
  query classe($id: ID!) {
    classe(id: $id) {
      id
      name
    }
  }
`;

export interface ClasseData {
  id: string;
  name: string;
}

export const useClasse = (options: QueryHookOptions<{ classe: ClasseData }, {}> = {}) =>
  useLocalQuery(classeQuery, options);
