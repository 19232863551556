import Pologone from 'components/icons/Polygone';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import ModalVideo from '../../ux/ModalVideo/ModalVideo';
import classes from '../Card/Card.module.scss';

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  footer?: string;
  medicineId?: string;
  dci?: string;
  deviceType?: string;
  medicinePhoto?: string;
  from?: string;
  typeVideoOpen?: boolean;
  videoData?: { url: string; qrCode: string };
  medidineDescription?: { classe: string; dci: string; device: string; dosage: string };
  classNameButton?: string;
}

const VideoCard: React.FC<CardProps> = ({
  title,
  medicineId,
  footer,
  className,
  medicinePhoto,
  deviceType,
  from,
  dci,
  typeVideoOpen,
  videoData = { url: 'http://splf.fr/ventoline/&flux=3', qrCode: 'qrTest' },
  medidineDescription,
  classNameButton,
  ...rest
}) => {
  const location = useLocation();
  const [videoModal, setvideoModal] = useState(false);
  return (
    <div
      id={medicineId}
      className={classNames(
        `flex flex-col text-blue-600 hover:text-white bg-white rounded-5 w-full`,
        'hover:border-green border border-grey hover:bg-green ',
        classes.container,
        !location.pathname.includes('/indications') ? classes.containerAdjusted : classes.containerAdjustedMin,
        className,
      )}
    >
      <div
        {...rest}
        className={classNames('flex-1', classes.backgroundImage)}
        style={{
          backgroundImage: `url(${medicinePhoto})`,
        }}
      >
        <div className={classNames('relative', classes.video)}>
          {/* eslint-disable-next-line */}
          <img alt="fdsf" src={medicinePhoto} className="object-contain w-auto h-275 hidden" />
        </div>

        <div className={classNames('gap-3  flex-col items-center justify-center hidden h-60 ', classes.btns)}>
          {typeVideoOpen ? (
            <button
              className=" font-bold w-9/12 flex items-center justify-center gap-3 bg-green  hover:bg-white hover:text-green text-grey-100 hover:outline-none font-medium border border-white h-12 rounded text-sm"
              onClick={() => {
                setvideoModal(true);
              }}
            >
              <Pologone height={40} width={20} className="mt-1   h-14 flex items-center" />
              Voir la vidéo
            </button>
          ) : (
            <button
              className="font-bold w-9/12 flex items-center justify-center gap-3 bg-green hover:bg-white hover:text-green text-grey-100 hover:outline-none font-medium border border-white h-12 rounded text-sm"
              onClick={() => {
                setvideoModal(true);
              }}
            >
              <Pologone height={40} width={20} className="mt-1   h-14 flex items-center" />
              Voir la vidéo
            </button>
          )}

          <ModalVideo
            from={from}
            onClose={() => setvideoModal(false)}
            medidineDescription={medidineDescription}
            title={title}
            deviceType={`${title} ${!typeVideoOpen ? 'en Stylo pré-rempli' : 'en Seringue pré-remplie'}`}
            medicineId={medicineId}
            videoData={videoData}
            open={videoModal}
          />
        </div>
      </div>
      <div>
        <div className="text-base px-3 py-2 pb-0">
          <div className="font-bold">
            {' '}
            {title?.split('®').map((e: any, index: number, array: any) => (
              <>
                {e}
                {array.length > 1 && index !== array.length - 1 && <span className="text-3xl">®</span>}
              </>
            ))}
          </div>
          {true && typeVideoOpen ? (
            <div className="italic">{dci?.replace(/^./, dci[0].toUpperCase())} (Seringue pré-remplie)</div>
          ) : (
            <div className="italic">{dci?.replace(/^./, dci[0].toUpperCase())} (Stylo pré-rempli)</div>
          )}
        </div>
        <div className=" w-full">
          <div className="flex font-medium px-3 pt-1 pb-3">
            <div
              className={classNames(
                'flex-1 mr-2 text-blue-950 text-xs 3_5xl:text-lg pt-3',
                footer?.trim().split(' ').length === 1 && 'truncate',
                classes.footer,
              )}
            >
              {footer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoCard;
